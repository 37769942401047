import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { useTranslations } from '../../../hooks/useTranslations'
import { Discount } from './Discount'
import { Price } from './Price'

import {
  IDiscount,
  IIndivPrice,
} from '../../../store/reducers/SpecialsReducer/types'
import { CategoryItemText, Slider, SliderContainer } from './styled'
import { useOpenProductModal } from 'hooks/useOpenProductModal'
import { useOpenQRMProduct } from 'featureSliced/shared/hooks/useOpenProductModal'
import { IProduct } from 'store/reducers/CategoriesReducer/types'

// TODO: highlight special if it's added to bucket
export const Specials = () => {
  const t = useTranslations()
  const openCCProductModal = useOpenProductModal()
  const openQRMProductModal = useOpenQRMProduct()
  const handleProductClick = (product: IProduct, categoryName?: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      openQRMProductModal(product, categoryName)
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      openCCProductModal(product, categoryName)
    }
  }

  const { categories } = useMySelector((state) => state)
  const { discounts, individualPrices } = useMySelector(
    (state) => state.specials
  )

  return (
    <>
      <RenderIf
        condition={
          (!!discounts?.length || !!individualPrices?.length) &&
          !!categories?.find((el) => el.products?.length)
        }
      >
        <CategoryItemText className="special_category">
          {t('common.promotions')}
        </CategoryItemText>
      </RenderIf>
      <SliderContainer>
        <Slider>
          {discounts?.map((discount: IDiscount) => (
            <Discount
              key={discount.productId}
              discount={discount}
              handleProductClick={handleProductClick}
            />
          ))}
          {individualPrices?.map((price: IIndivPrice) => (
            <Price
              key={price.productId}
              individualPrice={price}
              handleProductClick={handleProductClick}
            />
          ))}
        </Slider>
      </SliderContainer>
    </>
  )
}
