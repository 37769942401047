import { ITableCloudItems } from 'store/reducers/QRMenuTableReducer/types'

export const getProductTotalPrice = (product: ITableCloudItems) => {
  const itemPrice = product.count * product.price
  const subItemPrice = JSON.parse(product.menuItems || '[]')?.reduce(
    (menuAcc: number, menuCurr: ITableCloudItems) => {
      return (menuAcc += menuCurr.count * menuCurr.price)
    },
    0
  )
  return itemPrice + subItemPrice
}
