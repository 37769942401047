import styled from 'styled-components'

export const InstructionsBlock = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 210px;
    color: ${({ theme }) => theme.FontColor};
    font-size: 12px;
    margin-bottom: 8px;
  }
`
