import { FC } from 'react'
import {
  VisaElectron,
  Maestro,
  Dankort,
  UnionPay,
  Visa,
  Mastercard,
  Amex,
  Diners,
  Discover,
  Jcb,
} from 'react-pay-icons'

const getCCproviderCode = (cardMask: string, brandName: string) => {
  const re: any = {
    VisaElectron: /^(4026|417500|4405|4508|4844|4913|4917)[x0-9]+$/,
    Maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)[x0-9]+$/,
    Dankort: /^(5019)[x0-9]+$/,
    // interpayment: /^(636)[x0-9]+$/,
    UnionPay: /^(62|88)[x0-9]+$/,
    Visa: /^4[x0-9]{12}(?:[x0-9]{3})?$/,
    Mastercard: /^5[1-5][x0-9]{14}$/,
    Amex: /^3[47][x0-9]{13}$/,
    Diners: /^3(?:0[0-5]|[68][x0-9])[x0-9]{11}$/,
    Discover: /^6(?:011|5[x0-9]{2})[x0-9]{12}$/,
    Jcb: /^(?:2131|1800|35[x0-9]{3})[x0-9]{11}$/,
  }

  for (const key in re) {
    if (re[key].test(cardMask?.toLowerCase())) {
      return key
    }
    if (key.toLowerCase() === brandName?.toLowerCase()) {
      return key
    }
  }
}

export const CCProvider: FC<any> = ({
  cardMask,
  brandName,
  height = 16,
  display = 'inline',
  borderRadius = '3px',
}) => {
  const customStyles = { height, display, borderRadius }
  const getComponent = () => {
    switch (getCCproviderCode(cardMask, brandName)) {
      case 'VisaElectron':
        return <VisaElectron style={customStyles} />
      case 'Maestro':
        return <Maestro style={customStyles} />
      case 'Dankort':
        return <Dankort style={customStyles} />
      case 'UnionPay':
        return <UnionPay style={customStyles} />
      case 'Visa':
        return <Visa style={customStyles} />
      case 'Mastercard':
        return <Mastercard style={customStyles} />
      case 'Amex':
        return <Amex style={customStyles} />
      case 'Diners':
        return <Diners style={customStyles} />
      case 'Discover':
        return <Discover style={customStyles} />
      case 'Jcb':
        return <Jcb style={customStyles} />
      default:
        return <></>
    }
  }

  return <>{getComponent()}</>
}
