import { ICartProduct } from 'store/generalTypes'
import { IProduct } from '../CategoriesReducer/types'

export const initialState: IBucket = {
  products: [],
}

export interface IBucket {
  products: IBucketProduct[]
}

export interface IMenuItemsBucket {
  categoryId: number
  categoryId_origin: number
  count: number
  imageUrl: string
  kitchenInfo: any[]
  name: string
  price: string
  productId: number
}

export interface IBucketProduct extends ICartProduct {
  menuItems?: IMenuItemsBucket[]
  kitchenInfo: string
  addedAsWeight: boolean
  uniqueId: string
}

export interface IProductWithBucketProduct extends IProduct, IBucketProduct {
  kitchenInfo: string
  uniqueId: string
  menuItems: any[]
}
