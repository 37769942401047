import styled from 'styled-components'

export const BranchPage = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::after {
    content: unset;
  }
`

export const BranchTitle = styled.p`
  height: 2rem;
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.FontColor};
`

export const TableInputContainer = styled.div`
  display: flex;
  border-radius: 5px;
  overflow-x: hidden;
  align-items: center;
  width: 80%;
  position: relative;
`

export const TablePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

export const TableInput = styled.input`
  border: 0px;
  outline: none;
  height: 58px;
  width: calc(100% - 121px);
  border-radius: 0 5px 5px 0;
  text-align: center;
  font-size: 1.5rem;
  color: #3c3c32;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
`

export const NumberDiv = styled.span`
  background-color: #c4c4c4;
  color: #fff;
  height: 58px;
  width: 58px;
  text-align: center;
  line-height: 58px;
  font-size: 1.5rem;
`

export const OrJustHint = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.FontColor};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TableTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TableCaptionError = styled.p`
  color: #f00;
  height: 24px;
`
