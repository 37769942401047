import { getPrice } from 'utils/getProductPrice'
import {
  IBucketProduct,
  IMenuItemsBucket,
} from 'store/reducers/QRMenuBucketReducer/types'
import { ITableCloudItems } from 'store/reducers/QRMenuTableReducer/types'
import { getParsedMenuItems } from './getParsedMenuItems'

export const getTotalPrice = (
  selectedProducts: IBucketProduct[] | ITableCloudItems[]
): number => {
  return [...selectedProducts]?.reduce(
    (acc: number, curr: ITableCloudItems | IBucketProduct) => {
      const menuItems = getParsedMenuItems(curr.menuItems)
      const menuItemsPrice =
        menuItems?.reduce((mAcc: number, mCurr: IMenuItemsBucket) => {
          return (mAcc += mCurr.count * getPrice(mCurr))
        }, 0) || 0
      return (acc += (curr.count ?? 1) * (getPrice(curr) + menuItemsPrice))
    },
    0
  )
}
