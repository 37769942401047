import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { convertToPrice } from 'utils/convertToPrice'
import { getPrice } from 'utils/getProductPrice'

import {
  MinMaxWarning,
  ProductTitlePrice,
  ProductTotalPrice,
  TitleAndPriceContainer,
} from './styled'

type TitleAndPriceProps = {
  product: IProduct
  currentAmount: number
}

export const TitleAndPrice: FC<TitleAndPriceProps> = ({
  product,
  currentAmount = 1,
}) => {
  // Store
  const Currency = useMySelector((state) => state.app.companyData.Currency)

  return (
    <TitleAndPriceContainer>
      <p>{product.title}</p>
      <RenderIf
        condition={
          !!product.ClickCollectOrderMin && !!product.ClickCollectOrderMax
        }
      >
        <MinMaxWarning>
          {product.ClickCollectOrderMin
            ? `Min. ${product.ClickCollectOrderMin}`
            : ''}
          {product.ClickCollectOrderMin && product.ClickCollectOrderMax
            ? ' / '
            : ''}
          {product.ClickCollectOrderMax
            ? `Max. ${product.ClickCollectOrderMax}`
            : ''}
        </MinMaxWarning>
      </RenderIf>
      <RenderIf
        condition={product.unitSellCode === 1 && Boolean(getPrice(product))}
      >
        <ProductTitlePrice>
          <ProductTotalPrice>
            {convertToPrice(getPrice(product) * currentAmount)}&thinsp;
            {Currency || ''}
          </ProductTotalPrice>
        </ProductTitlePrice>
      </RenderIf>
    </TitleAndPriceContainer>
  )
}
