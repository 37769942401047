import { ModalProps } from 'store/reducers/ModalsReducer/types'
import { useOpenModal } from 'hooks/useOpenModal'
import {
  IBucketProduct,
  IProductWithBucketProduct,
} from 'store/reducers/QRMenuBucketReducer/types'
import { useMySelector } from 'hooks/useMySelector'
import { UpdateProduct } from './modals/UpdateProduct'

export type OpenProductModalType = (
  product: IBucketProduct,
  categoryName?: string
) => void

export const useUpdateBucketProduct = (): OpenProductModalType => {
  const openModal = useOpenModal()
  const productsList = useMySelector((state) => state.products.productList)

  const openProductModal = (
    product: IBucketProduct,
    // TODO: translations
    categoryName = 'UPDATE'
  ) => {
    const getFullProduct = () => {
      const ogProduct = productsList?.find((p) => p.id === product.productId)
      return {
        ...ogProduct,
        ...product,
      }
    }

    openModal({
      id: `ADD_PRODUCT_${product.uniqueId}`,
      title: categoryName,
      components: [
        ({ closeThisModal }: ModalProps) => (
          <UpdateProduct
            product={getFullProduct() as IProductWithBucketProduct}
            bucketProduct={product}
            closeProduct={closeThisModal}
          />
        ),
      ],
    })
  }

  return openProductModal
}
