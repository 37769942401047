import { FC, useState } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { errorHandler } from '../../../components/ErrorHandler'
import { getCategoriesData } from '../../../store/reducers/CategoriesReducer/actionCreator'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { FiltersSlice } from '../../../store/reducers/FiltersReducer'
import { useMySelector } from '../../../hooks/useMySelector'
import { useMyDispatch } from '../../../hooks/useMyDispatch'
import { Search } from '../../../components/Search'
import { TimeLocation } from '../../../components/TimeLocation'
import { useOpenModal } from '../../../hooks/useOpenModal'
import { AreYouSure } from './AreYouSure_Modal'
import { usePromptParams } from '../../Welcome/usePromptParams'
import Animated from 'react-mount-animation'

import { IconStroked } from '../../../components/IconsContainers/styled'
import { toggleScroll } from '../../../utils/toggleScroll'
import { AppSlice } from '../../../store/reducers/AppReducer'
import { AllergenSlider } from './AllergenSlider'

import { ReactComponent as ChevronDown } from '../../../assets/chevronDown.svg'
import {
  SliderContainer,
  Slider,
  Grid,
  SliderItem,
  GridItem,
  CategoriesOpener,
  CategoriesOpenerFlipped,
  CategoriesOpenerContainer,
} from './styled'
import { getContrastColor } from '../../../utils/getContrastColor'

type CategoriesSliderProps = {
  isInputShowing: boolean
  hideInput: () => void
  reloadMenuData: () => Promise<void>
  applyCategoriesFilter: () => void
}

export const CategoriesSlider: FC<CategoriesSliderProps> = ({
  isInputShowing,
  hideInput,
  reloadMenuData,
  applyCategoriesFilter,
}) => {
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const openPromptModal = usePromptParams()
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
  const { addAllergen, deleteAllergen, addDiet, deleteDiet } =
    FiltersSlice.actions

  // Store
  const { updateLastActiveCategoryId } = AppSlice.actions
  const { colour } = useMySelector((state) => state.app.interfaceStyles)
  const { isTopScrolled } = useMySelector((state) => state.common)
  const { categories } = useMySelector((state) => state)
  const { interfaceStyles, lastActiveCategoryId } = useMySelector(
    (state) => state.app
  )
  const { allergens: selectedAllergens, diet } = useMySelector(
    (state) => state.filters
  )

  // State
  const [inputValue, setInputValue] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<number>(0)
  const [displayType, setDisplayType] = useState<'slider' | 'grid'>('slider')

  // Functions
  const setSelected = async (originId: number | string) => {
    let id: number | string

    if (typeof originId === 'number') {
      id = originId
    } else {
      id = originId.split('_')[originId.split('_').length - 1]
    }

    const listOfElements: any =
      document.getElementsByClassName(`slider_category`)

    const newElementIndex = Array.prototype.findIndex.call(
      listOfElements,
      (element: any) => {
        return element.id === `slider_category_${id}`
      }
    )

    let activeElementIndex = Array.prototype.findIndex.call(
      listOfElements,
      (element: any) => {
        return element.className.includes('SLIDER_BUTTON--ACTIVE')
      }
    )
    if (activeElementIndex === -1) {
      activeElementIndex = Array.prototype.findIndex.call(
        listOfElements,
        (element: any) => {
          return element.id === lastActiveCategoryId
        }
      )
    }

    const sliderElement: any = document.getElementById(`slider_category_${id}`)
    const listElement: any = document.getElementById(`container_category_${id}`)

    Array.prototype.findIndex.call(
      [...listOfElements, sliderElement],
      (element: any) => {
        element.style = null
        element.classList.remove('SLIDER_BUTTON--TARGET')
      }
    )

    if (sliderElement) {
      sliderElement.classList.add('SLIDER_BUTTON--TARGET')
      sliderElement.style['background'] = interfaceStyles.colour
      sliderElement.style['color'] = getContrastColor(interfaceStyles.colour)
      sliderElement.scrollIntoView({ inline: 'center', behavior: 'auto' })
    }

    if (listElement) {
      if (activeElementIndex >= newElementIndex) {
        listElement.scrollIntoView({ block: 'start', behavior: 'auto' })
      }
      if (newElementIndex > activeElementIndex) {
        const dims = listElement.getBoundingClientRect()
        window.scrollTo(0, dims.top + window.scrollY - getAdditionalOffset())
      }
    }
    dispatch(updateLastActiveCategoryId(`slider_category_${id}`))
  }

  const slideToView = (id: string) => {
    const sliderElement: any = document.getElementById(id)
    if (sliderElement) {
      sliderElement.classList.add('SLIDER_BUTTON--ACTIVE')
      // sliderElement.style['background'] = 'red'
      sliderElement.style['background'] = interfaceStyles.colour
      sliderElement.style['color'] = getContrastColor(interfaceStyles.colour)
      sliderElement.scrollIntoView({ inline: 'center', behavior: 'auto' })
    }
  }

  const getAdditionalOffset = () => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      return 70
    } else {
      return 180
    }
  }

  const getCategories = async (search = '') => {
    try {
      if (guid && registerId) {
        dispatch(getCategoriesData(search))
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  const searchProducts = () => {
    getCategories(inputValue)
  }

  const handleSelectedAllergens = (id: number) => {
    if (selectedAllergens.includes(id)) {
      dispatch(deleteAllergen(id))
    }
    if (!selectedAllergens.includes(id)) {
      dispatch(addAllergen(id))
    }
    applyCategoriesFilter()
  }

  const handleSelectedDietItems = (id: string) => {
    if (diet.includes(id)) {
      dispatch(deleteDiet(id))
    }
    if (!diet.includes(id)) {
      dispatch(addDiet(id))
    }
    applyCategoriesFilter()
  }

  const getBannerCustomTop = (
    isTopScrolled: boolean,
    isInputShowing: boolean,
    isAllergensShowing: boolean
  ) => {
    let height = 60
    if (isTopScrolled) height += 70
    if (isAllergensShowing) height += 27
    if (isInputShowing) height += 50
    return `${height}px`
  }

  const editTimeLocation = () => {
    openModal({
      id: 'ARE_YOU_SURE_PROMPT',
      title: `Are you sure?`,
      components: [
        ({ closeThisModal }) => (
          <AreYouSure
            onDiscardConfirm={() =>
              openPromptModal(reloadMenuData, ['time', 'branch'], true)
            }
            closeModal={closeThisModal}
          />
        ),
      ],
    })
  }

  const handleCategoryClick = (id: number) => {
    setSelectedCategory(id)
    setSelected(id)
  }

  const handleGridCategoryClick = (id: number) => {
    setDisplayType('slider')
    setSelectedCategory(id)
  }

  const handleCategoriesType = () => {
    hideInput()
    setDisplayType((prevValue) => {
      if (prevValue === 'grid') {
        return 'slider'
      } else {
        setSelectedCategory(0)
        return 'grid'
      }
    })
  }

  const getSlideStyles = (index: number) => {
    if (!lastActiveCategoryId?.length && !index) {
      return { background: colour, color: getContrastColor(colour) }
    } else {
      return {}
    }
  }

  const getGridStyles = (categoryId: number) => {
    if (`slider_category_${categoryId}` === lastActiveCategoryId) {
      return { background: colour, color: getContrastColor(colour) }
    } else {
      return {}
    }
  }

  return (
    <div>
      <SliderContainer isTopScrolled={isTopScrolled}>
        <Animated.div
          show={(displayType === 'slider')?.toString()}
          time={0.001}
          delay={0.15}
          unmountDelay={0}
          onUnmountEnd={() => toggleScroll(true)}
          onMountEnd={() => {
            if (selectedCategory) {
              setSelected(selectedCategory)
            } else if (lastActiveCategoryId?.length) {
              slideToView(lastActiveCategoryId)
            }
          }}
        >
          <Slider>
            {categories
              ?.filter((cat) => !!cat?.products?.length)
              ?.map((category, i) => {
                return (
                  <SliderItem
                    key={`slider_${category.id}`}
                    className="slider_category"
                    id={`slider_category_${category.id}`}
                    style={getSlideStyles(i)}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </SliderItem>
                )
              })}
          </Slider>
        </Animated.div>
        <Animated.div
          show={displayType === 'grid'}
          mountAnim={`
            0% {max-height: 60px}
            100% {max-height: 70vh}
          `}
          unmountAnim={`
            0% {max-height: 70vh}
            100% {max-height: 0px}
          `}
          time={0.6}
          unmountTime={0.2}
          onUnmountEnd={() => toggleScroll(false)}
        >
          <Grid>
            {categories
              ?.filter((cat) => !!cat?.products?.length)
              ?.map((category, i) => {
                return (
                  <GridItem
                    key={`grid_${category.id}`}
                    className="slider_category"
                    id={`slider_category_${category.id}`}
                    style={getGridStyles(category.id)}
                    onClick={() => handleGridCategoryClick(category.id)}
                  >
                    {category.name}
                  </GridItem>
                )
              })}
          </Grid>
          <CategoriesOpenerContainer>
            <CategoriesOpenerFlipped onClick={handleCategoriesType}>
              <IconStroked color="#fff">
                <ChevronDown />
              </IconStroked>
            </CategoriesOpenerFlipped>
          </CategoriesOpenerContainer>
        </Animated.div>
      </SliderContainer>
      <RenderIf condition={isInputShowing}>
        <SliderContainer isTopScrolled={isTopScrolled} customPadding="60px">
          <Search
            inputValue={inputValue}
            setInputValue={setInputValue}
            onSearchClick={searchProducts}
          />
        </SliderContainer>
      </RenderIf>
      <RenderIf condition={!!selectedAllergens?.length || !!diet?.length}>
        <SliderContainer
          isTopScrolled={isTopScrolled}
          customPadding={isInputShowing ? '119px' : '60px'}
        >
          <AllergenSlider
            selectedAllergens={selectedAllergens}
            diet={diet}
            handleSelectedAllergens={handleSelectedAllergens}
            handleSelectedDietItems={handleSelectedDietItems}
          />
        </SliderContainer>
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <TimeLocation
          editTimeLocation={editTimeLocation}
          customTop={getBannerCustomTop(
            isTopScrolled,
            isInputShowing,
            !!selectedAllergens?.length || !!diet?.length
          )}
        />
      </RenderIf>
      <RenderIf condition={displayType === 'slider'}>
        <CategoriesOpener
          onClick={handleCategoriesType}
          customTop={getBannerCustomTop(
            isTopScrolled,
            isInputShowing,
            !!selectedAllergens?.length || !!diet?.length
          )}
        >
          <IconStroked color="#fff">
            <ChevronDown />
          </IconStroked>
        </CategoriesOpener>
      </RenderIf>
    </div>
  )
}
