import { FC, useEffect, useState } from 'react'
import Ping from 'ping-url'

import { getPrecision, roundValueTo } from 'utils/roundValueTo'
import { TableDiv } from 'featureSliced/widgets/Cart/styled'
import { useTranslations } from 'hooks/useTranslations'
import { convertToPrice } from 'utils/convertToPrice'
import { useMySelector } from 'hooks/useMySelector'

import {
  TipsContainer,
  TipsGrid,
  TipsModalButton,
  TipsModalButtonPercentage,
  TipsGridContainer,
  TipsInput,
  InputCurrency,
  TipsTotal,
  InputTitle,
  InputContainer,
  SubFooterTips,
} from './styled'

// TIPS_ARRAY must be 3-items lenth to have a correct layout.
const TIPS_ARRAY: [number, number, number] = [5, 10, 15]
export const DEFAULT_TIPS_VALUE = 5

type TipsProps = {
  total: number
  tipsValue: number
  onChange: (value: number) => void
}

export const Tips: FC<TipsProps> = ({ total, tipsValue, onChange }) => {
  // Hooks
  const t = useTranslations()

  //Store
  const { Currency } = useMySelector((state) => state.app.companyData)

  // State
  const [inputValue, setInputValue] = useState<number | string>()
  const [isConnectionSlow, setIsConnectionSlow] = useState<boolean>(false)

  // useEffects
  useEffect(() => {
    checkSpeed()
  }, [])

  useEffect(() => {
    onChange(getTipsValue(DEFAULT_TIPS_VALUE))
  }, [DEFAULT_TIPS_VALUE])

  useEffect(() => {
    setInputValue(tipsValue)
  }, [tipsValue])

  // Functions
  const handleTileClick = (value: number) => {
    if (tipsValue === value) {
      setInputValue(0)
      onChange(0)
    }
    if (tipsValue !== value) {
      setInputValue(convertToPrice(value))
      onChange(value)
    }
  }

  const getTipsValue = (percentage: number) => {
    const originalTipsValue = total * (percentage / 100)
    const roundedValue = preciseValue(originalTipsValue)
    return roundedValue
  }

  const preciseValue = (value: number) => {
    const precision = getPrecision(Currency)
    const roundedValue = roundValueTo(value, precision)
    return roundedValue
  }

  const handleInputChange = (e: any) => {
    onChange(preciseValue(+e.currentTarget.value))
  }

  const checkSpeed = () => {
    Ping.check('https://google.com').then(
      (res) => {
        setIsConnectionSlow(res.time > 1000)
      },
      (err) => {
        setIsConnectionSlow(true)
        console.log(`Connection error: ${err.msg}`)
      }
    )
  }
  return (
    <TipsContainer>
      <div>
        <TableDiv>{t('order.tips')}</TableDiv>
        <TipsGrid>
          {TIPS_ARRAY.map((percentage) => {
            const value = getTipsValue(percentage)
            return (
              <TipsModalButton
                key={`tips_${value}`}
                onClick={() => handleTileClick(value)}
                buttonType={tipsValue === value ? 'stroke' : 'gray'}
              >
                <TipsModalButtonPercentage>
                  {percentage}%
                </TipsModalButtonPercentage>
                <p style={{ margin: '0' }}>
                  {convertToPrice(value)} {Currency || ''}
                </p>
              </TipsModalButton>
            )
          })}
          <TipsGridContainer gc="1 / -1" gr="2 / 3">
            <InputTitle>{t('order.manualInput')}:</InputTitle>
            <InputContainer height="60px">
              <TipsInput
                inputMode="numeric"
                textColor="#434343"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => {
                  setInputValue('')
                  setInputValue(tipsValue)
                }}
                onBlur={() => setInputValue(convertToPrice(inputValue))}
              />
              <InputCurrency>{Currency || ''}</InputCurrency>
            </InputContainer>
          </TipsGridContainer>
        </TipsGrid>
      </div>
      {/* <SubFooterTips
        isSlow={isConnectionSlow}
        text={t('footer.slowInternetConnectionDisclaimer')}
      >
        <TipsTotal>
          <span>{t('common.subTotal')}:</span>
          <span>
            {convertToPrice(total)} {Currency || ''}
          </span>
        </TipsTotal>
        <TipsTotal>
          <span>{t('order.tips')}:</span>
          <span>
            {convertToPrice(tipsValue)} {Currency || ''}
          </span>
        </TipsTotal>
      </SubFooterTips> */}
    </TipsContainer>
  )
}
