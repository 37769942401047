import { FC, useState } from 'react'
import { RenderIf } from 'components/RenderIf'
import InfoItem from './InfoItem'

import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'

import history from 'featureSliced/shared/utils/history'
import { ShareButtons } from 'featureSliced/widgets/ShareButtons'
import { AGBModal } from 'featureSliced/features/TermsConditions/AGB'
import { TermsConditions } from 'featureSliced/features/TermsConditions'
import { PageType } from 'featureSliced/padges/MainScreen'
import { Button } from 'featureSliced/entities/Button'

import { ReactComponent as Account } from 'assets/account.svg'
import { ReactComponent as CrossImg } from 'assets/handlers/cross.svg'
import { ReactComponent as CellPhone } from 'assets/cover/cellPhone.svg'
import { ReactComponent as Marker } from 'assets/cover/markerStroke.svg'
import { ReactComponent as Markers } from 'assets/cover/markersStroke.svg'

import {
  AGBText,
  CoverSidebarCloseHandler,
  RestaurantInfoBanner,
  SidebarCloser,
  SidebarContainer,
  SidebarLink,
} from './styled'

type SidebarProps = {
  isSidebarShowing: boolean
  openProfileModal: (page?: PageType) => void
  setIsSidebarShowing: (value: boolean) => void
  userName?: string | false
  openAuthModal: () => void
}

export const Sidebar: FC<SidebarProps> = ({
  isSidebarShowing,
  openProfileModal,
  setIsSidebarShowing,
  userName,
  openAuthModal,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  // Store
  const { restaurants } = useMySelector((state) => state)
  const { companyData, address } = useMySelector((state) => state.app)
  const { isReservationsShown, isSocialsShown, loginAvailable } = useMySelector(
    (state) => state.app.menu
  )

  // Functions
  const navigateToReservation = () => {
    history.push('/reservation')
  }

  const openTermsAndConditions = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'TERMS_AND_CONDITIONS',
      title: 'Terms and Conditions',
      components: [() => <TermsConditions />],
    })
  }

  const openAGB = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'AGB_MODAL',
      title: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
      components: [() => <AGBModal />],
    })
  }

  return (
    <>
      <SidebarCloser
        isSidebarShowing={isSidebarShowing}
        onClick={() => setIsSidebarShowing(false)}
      >
        <CoverSidebarCloseHandler isSidebarShowing={isSidebarShowing}>
          <Button buttonType="common" height="38px" width="38px">
            <CrossImg width="15" height="15" />
          </Button>
        </CoverSidebarCloseHandler>
      </SidebarCloser>
      <SidebarContainer isSidebarShowing={isSidebarShowing}>
        <RenderIf condition={loginAvailable && !userName}>
          <InfoItem
            title={t('sidebar.account')}
            Icon={Account}
            data={[t('sidebar.logInSignUp')]}
            onClick={openAuthModal}
          />
        </RenderIf>
        <RenderIf condition={loginAvailable && !!userName}>
          <InfoItem
            title={t('sidebar.account')}
            Icon={Account}
            data={[userName]}
            // NOTE: callback is mandatory.
            onClick={() => openProfileModal()}
          />
        </RenderIf>
        <RenderIf condition={!!companyData.CompanyName}>
          <RestaurantInfoBanner>{companyData.CompanyName}</RestaurantInfoBanner>
        </RenderIf>
        <RenderIf
          condition={
            process.env.REACT_APP_IS_CLIC === '0' &&
            Boolean(isReservationsShown)
          }
        >
          <InfoItem
            title={t('profile.toReservate')}
            Icon={CellPhone}
            components={
              <>
                <SidebarLink href={`tel:${companyData.Phone}`}>
                  {companyData.Phone}
                </SidebarLink>
                <SidebarLink onClick={navigateToReservation}>
                  Online
                </SidebarLink>
              </>
            }
          />
        </RenderIf>
        <InfoItem
          title={t('sidebar.address')}
          Icon={Marker}
          data={[
            companyData.CompanyName,
            companyData.Street,
            `${companyData.PostCode || ''}${companyData.PostCode ? ', ' : ''}${
              companyData.City || ''
            }`,
          ]}
        />
        <RenderIf
          condition={
            process.env.REACT_APP_IS_CLIC === '0' &&
            Boolean(!isReservationsShown)
          }
        >
          <InfoItem
            title={t('tableReservation.contactInfo')}
            Icon={CellPhone}
            components={
              <SidebarLink href={`tel:${address.Phone}`}>
                {address.Phone}
              </SidebarLink>
            }
            data={[address.Mail, address.Website]}
          />
        </RenderIf>
        <RenderIf condition={restaurants?.length > 1}>
          <InfoItem
            title={t('sidebar.moreRestaurants')}
            Icon={Markers}
            link={`restaurants`}
            data={[t('sidebar.locations')]}
          />
        </RenderIf>
        <RenderIf condition={Boolean(isSocialsShown)}>
          <ShareButtons />
        </RenderIf>
        <AGBText>
          <span aria-hidden="true" onClick={openAGB}>
            den AGB
          </span>{' '}
          und{' '}
          <span aria-hidden="true" onClick={openTermsAndConditions}>
            DSGVO
          </span>{' '}
        </AGBText>
      </SidebarContainer>
    </>
  )
}
