import { IMenuItemExtended, IOrderProduct } from '../Order/types'

export const DEFAULT_TIPS_VALUE = 5

export type CurrentPageType = 'cart' | 'split' | 'tips' | 'selfService'

export type OrderItem = {
  categoryId: number
  count: number
  id: number
  imageUrl: string
  itemId: number
  kitchenInfo: null
  onKDS: number
  price: number
  productId: number
  title: string
  menuItems?: IMenuItemExtended[]
}

export interface PaymentDetailsType {
  RecordTTL?: number
  caption?: string | null
  clientId?: string
  createdAt?: number
  currency?: string
  lastModified: number
  orderGUID?: string
  orderItems: OrderItem[]
  orderState?: 0 | 1
  paymentButtonRequestData?: {
    merchantId: string
    merchantName: string
    refno: string
    countryCode: string
  }
  // restaurantId?: string
  tableId?: number
  total: number
}

export interface PaymentDetailsTypeExtended extends PaymentDetailsType {
  products: IOrderProduct[]
}

export type ProductToPay = {
  itemId: number
  count: number
  maxAmount?: number
  youthProtectionValue?: '0' | '1' | '2'
}
