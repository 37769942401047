import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'
import { useTranslations } from 'hooks/useTranslations'
import { FC, useState } from 'react'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { convertToPrice } from 'utils/convertToPrice'
import { getPrice } from 'utils/getProductPrice'

import {
  CutDisclaimer,
  PriceInfoContainer,
  ProductMainPrice,
  ProductSellCodePrice,
  UnitSellCodePriceContainer,
} from './styled'

type TitleAndPriceProps = {
  product: IProduct
  currentAmount: number
}

export const UnitSellCodePrice: FC<TitleAndPriceProps> = ({
  product,
  currentAmount,
}) => {
  // Hooks
  const t = useTranslations()

  // Store
  const Currency = useMySelector((state) => state.app.companyData.Currency)

  // State
  const [isDisclaimerShown, setIsDisclaimerShown] = useState<boolean>(false)

  return (
    <RenderIf
      condition={product.unitSellCode === 2 || product.unitSellCode === 3}
    >
      <UnitSellCodePriceContainer>
        <p>
          {product.unitSellCode === 2 ? '100g' : ''}
          {product.unitSellCode === 3 ? '1kg' : ''}
        </p>
        <p> = </p>
        <p>
          {convertToPrice(getPrice(product))}&thinsp;
          {Currency || ''}
        </p>
        <PriceInfoContainer>
          <ProductMainPrice onClick={() => setIsDisclaimerShown(true)}>
            i
          </ProductMainPrice>
          <ProductMainPrice>
            <ProductSellCodePrice>
              {convertToPrice(getPrice(product) * currentAmount)}&thinsp;
              {Currency || ''}
            </ProductSellCodePrice>
          </ProductMainPrice>
        </PriceInfoContainer>
      </UnitSellCodePriceContainer>
      <RenderIf condition={isDisclaimerShown}>
        <CutDisclaimer>{t('products.cutDisclaimer')}</CutDisclaimer>
      </RenderIf>
    </RenderIf>
  )
}
