import styled from 'styled-components'

type SecondaryImageProps = {
  isActive?: boolean
}

type KitchenItemProps = {
  isActive: boolean
}

// ProductMain

export const ProductMainContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(85vh - 198px);
  overflow-y: scroll;
  & > div,
  p {
    width: 100%;
  }
`

// Image

export const ProductMainImageContainer = styled.div`
  min-height: 225px;
  height: 225px;
  min-width: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProductMainImage = styled.img`
  position: absolute;
  object-fit: cover;
  width: 98%;
  height: 200px;
  border-radius: 10px;
`

export const AgeProtection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #cdcdcd;
  color: #fff;
`

export const SecondaryImage = styled('div')<SecondaryImageProps>`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  width: 50px;
  border-radius: 50%;

  background-color: ${({ isActive }) => (isActive ? '#e32323' : '#cdcdcd')};
  img {
    width: 18px;
  }
`

// Title And Price

export const TitleAndPriceContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  min-height: 48px;
  margin: 0;
  margin-bottom: 15px;
  padding-right: 135px;
  padding-bottom: 24px;
  color: ${({ theme }) => theme.FontColor};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
`

export const ProductTitlePrice = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`

export const MinMaxWarning = styled(ProductTitlePrice)`
  left: 0;
  right: unset;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`

export const ProductTotalPrice = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colour};
`

// UnitSellCodePrice

export const UnitSellCodePriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  color: ${({ theme }) => theme.FontColor};
  & > p {
    color: ${({ theme }) => theme.FontColor};
    margin-right: 5px;
    font-size: 12px;
    width: auto;
  }
  p:nth-child(3) {
    font-weight: 600;
  }
`

export const PriceInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const ProductMainPrice = styled.div`
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 9px 22px;
`

export const ProductSellCodePrice = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colour};
`

export const CutDisclaimer = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin: 15px 0;
  color: ${({ theme }) => theme.FontColor};
`

// Kitchen Info

export const KitchenInfoTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 10px;
`

export const KitchenItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    height: 0px;
  }
`

export const KitchenItem = styled('div')<KitchenItemProps>`
  color: #fff;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colour : theme.ItemsBorders};
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  line-height: 100%;
  display: flex;
  align-items: center;
`

// Allergens

export const AllergensSections = styled.div`
  display: flex;
  flex-direction: column;
`

export const AllergensTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 10px;
`

export const AllergensContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  margin: 0;
  margin-bottom: 10px;
  position: relative;
`

export const AllergenItem = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 25px;
    white-space: nowrap;
  }
`

export const Allergen = styled.img`
  background: #ff5151;
  height: 34px;
  width: 34px;
  object-fit: cover;
  margin-right: 7px;
  border-radius: 17px;
`

export const AllergenText = styled.p`
  color: ${({ theme }) => theme.FontColor};
`

// Description

export const DescriptionTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 10px;
`

export const DescriptionP = styled.p`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
`

// Footer

export const Divider = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 103px;
  border-bottom: 1px solid #cdcdcd;
  height: 20px;
  background-image: linear-gradient(
    transparent,
    ${({ theme }) => theme.MainBackGround}
  );
`

export const FooterContainer = styled.div`
  margin-top: 20px;
  height: 58px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;
`
