import styled, { keyframes } from 'styled-components'
import { ButtonType } from './types'
import { getContrastColor } from 'featureSliced/shared/utils/getContrastColor'
import { get } from 'featureSliced/shared/utils/lodashGet'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`

const spinner = keyframes`
  0%
  {
    transform: rotate(0turn);
  }
  100%
  {
    transform: rotate(1turn);
  }
`

export const ButtonSpinner = styled.div`
  pointer-events: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  animation: ${spinner} 0.8s ease infinite;
`

export const Button = styled('button')<ButtonType>`
  width: ${({ width, isShort }) => (isShort ? '58px' : width || '210px')};
  height: ${({ height }) => height || '58px'};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, buttonType }) =>
    `${get(
      theme,
      buttonStyles[buttonType]?.default.background,
      buttonStyles[buttonType]?.default.background
    )}${buttonStyles[buttonType]?.default.opacity}`};

  color: ${({ theme, buttonType }) => {
    return getContrastColor(
      buttonStyles[buttonType]?.default.opacity === '40'
        ? theme.MainBackGround
        : `${get(
            theme,
            buttonStyles[buttonType]?.default.background,
            buttonStyles[buttonType]?.default.background
          )}${buttonStyles[buttonType]?.default.opacity}`
    )
  }};

  border: ${({ theme, buttonType }) =>
    `1px solid ${get(
      theme,
      buttonStyles[buttonType]?.default.border,
      buttonStyles[buttonType]?.default.border
    )}`};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, buttonType }) =>
      `${get(
        theme,
        buttonStyles[buttonType]?.hover.background,
        buttonStyles[buttonType]?.hover.background
      )}${buttonStyles[buttonType]?.hover.opacity}`};

    color: ${({ theme, buttonType }) =>
      get(
        theme,
        buttonStyles[buttonType]?.hover.color,
        buttonStyles[buttonType]?.hover.color
      )};

    border: ${({ theme, buttonType }) =>
      `1px solid ${get(
        theme,
        buttonStyles[buttonType]?.hover.border,
        buttonStyles[buttonType]?.hover.border
      )}`};
  }

  &:active {
    cursor: pointer;
    background-color: ${({ theme, buttonType }) =>
      `${get(
        theme,
        buttonStyles[buttonType]?.active.background,
        buttonStyles[buttonType]?.active.background
      )}${buttonStyles[buttonType]?.active.opacity}`};

    color: ${({ theme, buttonType }) =>
      get(
        theme,
        buttonStyles[buttonType]?.active.color,
        buttonStyles[buttonType]?.active.color
      )};

    border: ${({ theme, buttonType }) =>
      `1px solid ${get(
        theme,
        buttonStyles[buttonType]?.active.border,
        buttonStyles[buttonType]?.active.border
      )}`};
  }

  &:disabled {
    background-color: ${({ theme, buttonType }) =>
      get(
        theme,
        buttonStyles[buttonType]?.disabled.background,
        buttonStyles[buttonType]?.disabled.background
      )};

    color: ${({ theme, buttonType }) =>
      get(
        theme,
        buttonStyles[buttonType]?.disabled.color,
        buttonStyles[buttonType]?.disabled.color
      )};

    border: ${({ theme, buttonType }) =>
      `1px solid ${get(
        theme,
        buttonStyles[buttonType]?.disabled.border,
        buttonStyles[buttonType]?.disabled.border
      )}`};
  }
`
const buttonStyles = {
  stroke: {
    default: {
      border: 'colour',
      background: 'colour',
      color: 'colour',
      opacity: '40',
    },
    active: {
      border: 'colour',
      background: 'colour',
      color: 'colour',
      opacity: '80',
    },
    hover: {
      border: 'colour',
      background: 'colour',
      color: 'colour',
      opacity: '40',
    },
    disabled: {
      border: '#cdcdcd',
      background: '#e1e1e125',
      color: '#cdcdcd',
    },
  },

  common: {
    default: {
      border: 'colour',
      background: 'colour',
      color: '#fff',
      opacity: 'ff',
    },
    active: {
      border: 'colour',
      background: 'colour',
      color: '#fff',
      opacity: 'cc',
    },
    hover: {
      border: 'colour',
      background: 'colour',
      color: '#fff',
      opacity: 'e6',
    },
    disabled: {
      border: '#cdcdcd',
      background: '#dddddd80',
      color: '#cdcdcd',
    },
  },

  gray: {
    default: {
      border: '#434343',
      background: '#ffffff',
      color: '#434343',
      opacity: '60',
    },
    active: {
      border: '#434343',
      background: '#dddddd',
      color: '#434343',
      opacity: '60',
    },
    hover: {
      border: '#434343',
      background: '#ffffff',
      color: '#434343',
      opacity: '60',
    },
    disabled: {
      border: '#cdcdcd',
      background: '#dddddd',
      color: '#cdcdcd',
    },
  },

  dark: {
    default: {
      border: '#686868',
      background: '#686868',
      color: '#ffffff',
      opacity: 'ff',
    },
    active: {
      border: '#434343',
      background: '#434343',
      color: '#ffffff',
      opacity: 'ff',
    },
    hover: {
      border: '#898989',
      background: '#898989',
      color: '#ffffff',
      opacity: 'ff',
    },
    disabled: {
      border: '#dddddd',
      background: '#dddddd',
      color: '#cdcdcd',
    },
  },
}
