import { FC, MouseEvent, useState } from 'react'

import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'
import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'
import { useOpenQRMProduct } from 'featureSliced/shared/hooks/useOpenProductModal'
import { ContextMenu } from 'components/ContextMenu'
import { Cart } from 'featureSliced/widgets/Cart'
import { RenderIf } from 'components/RenderIf'

import { useMyDispatch } from 'hooks/useMyDispatch'
import { useMySelector } from 'hooks/useMySelector'
import { useTranslations } from 'hooks/useTranslations'
import { useOpenProductModal } from 'hooks/useOpenProductModal'

import basket from 'assets/basket_small.svg'
import trashCan from 'assets/trashCan.svg'

import { IPosition } from './types'
import { BucketProductsContainer } from './styled'
import { ICartProduct } from 'store/generalTypes'
import { useUpdateBucketProduct } from 'featureSliced/shared/hooks/useOpenProductModal/useUpdateBucketProduct'

export const BucketProducts: FC = () => {
  const t = useTranslations()
  const dispatch = useMyDispatch()

  const openCCProductModal = useOpenProductModal()
  // const openQRMProductModal = useOpenQRMProduct()
  const openQRMProductModal = useUpdateBucketProduct()
  const { paymentVariant } = useMySelector((state) => state.app.menu)
  const bucketProducts = useMySelector((state) => state.bucket.products)
  const [selectedProduct, setSelectedProduct] = useState<IBucketProduct>()

  const [position, setPosition] = useState<IPosition | null>(null)
  const removeProductFromBucket = QRMBucketSlice.actions.removeProductFromBucket

  const productsList = useMySelector((state) => state.products.productList)

  // Function
  // const openProductModal = (productId: number) => {
  const openProductModal = (product: IBucketProduct) => {
    const fullProduct = productsList?.find((p) => p.id === product.productId)
    if (process.env.REACT_APP_IS_CLIC === '0') {
      fullProduct && openQRMProductModal(product, t('order.updateOrder'))
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      fullProduct && openCCProductModal(fullProduct, t('order.updateOrder'))
    }
  }

  const handleProductClick = (
    _productId: number,
    e: MouseEvent<HTMLElement>,
    product2: ICartProduct
  ) => {
    const product = bucketProducts?.find(
      (bP) => bP.uniqueId === product2.uniqueId
    )
    setSelectedProduct(product)
    setPosition({
      x: e.pageX,
      y: e.pageY,
    })
  }

  return (
    <BucketProductsContainer>
      <Cart products={bucketProducts} handleProductClick={handleProductClick} />
      <RenderIf condition={!!position}>
        <ContextMenu
          position={position as IPosition}
          discardPosition={() => setPosition(null)}
          items={contextMenuItems(
            t,
            selectedProduct as IBucketProduct,
            openProductModal,
            (uniqueId: string) => dispatch(removeProductFromBucket(uniqueId))
            // !selectedProduct?.menuItems
          )}
        />
      </RenderIf>
    </BucketProductsContainer>
  )
}

const contextMenuItems = (
  t: any,
  selectedProduct: IBucketProduct,
  handleEditClick: (product: IBucketProduct) => void,
  handleRemoveProduct: (id: string) => void
) => [
  {
    component: (
      <>
        <img src={basket} alt="Edit" />
        {t('buttons.edit')}
      </>
    ),
    onClick: () => handleEditClick(selectedProduct),
  },
  {
    component: (
      <>
        <img src={trashCan} alt="Remove" />
        {t('buttons.remove')}
      </>
    ),
    onClick: () => {
      handleRemoveProduct(selectedProduct.uniqueId as string)
    },
  },
]
