import qrMenuLogo from 'assets/prompt/qrMenu.svg'
import clickAndCollectLogo from 'assets/prompt/clickAndCollect.svg'
import { useMySelector } from 'hooks/useMySelector'
import { LogoContainer, LogoImage } from './styled'

export const Logo = () => {
  const { interfaceStyles } = useMySelector((state) => state.app)

  const getImageSrc = (logoImage?: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      return logoImage || qrMenuLogo
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      return logoImage || clickAndCollectLogo
    }
  }

  return (
    <LogoContainer>
      <LogoImage
        src={getImageSrc(interfaceStyles.logoImage)}
        alt="Logo"
        height="316.5"
        width="316.5"
      />
    </LogoContainer>
  )
}
