import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'
import { useTranslations } from 'hooks/useTranslations'
import { InstructionsBlock } from './styled'

export const QRMInstructions = () => {
  const t = useTranslations()
  const { selfService } = useMySelector((state) => state.app.menu)

  return (
    <RenderIf
      condition={process.env.REACT_APP_IS_CLIC === '0' && selfService === 1}
    >
      <InstructionsBlock>
        <p>1. {t('mainPage.orderAndPay')}</p>
        <p>2. {t('mainPage.smsRecieve')}</p>
        <p>3. {t('mainPage.counter')}</p>
      </InstructionsBlock>
    </RenderIf>
  )
}
