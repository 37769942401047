import { FC, MouseEvent, useEffect, useState } from 'react'
import { useTranslations } from '../../hooks/useTranslations'
import { RenderIf } from '../../components/RenderIf'
import { Products } from '../../components/Products'
import { IProduct } from '../../store/reducers/CategoriesReducer/types'
import { useMySelector } from '../../hooks/useMySelector'
import { IOrderProduct } from '../../sections/Order/types'

import basket from '../../assets/basket_small.svg'
import trashCan from '../../assets/trashCan.svg'

import { ContextMenu } from '../ContextMenu'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { SelectedProductsSlice } from '../../store/reducers/SelectedProductsReducer'
import { CheckboxContainer, SubFooter } from '../../sections/Payment/styled'
import { isAuth } from '../../utils/isAuth'
import { useOpenModal } from '../../hooks/useOpenModal'
import { AuthModal } from '../../sections/MainScreen/AuthModal'

import {
  PaymentProductsContainer,
  SignUpButton,
  SignUpDecorated,
  SignUpDescription,
  TableDiv,
} from './styled'
import { TermsAndConditionsCheckbox } from '../../sections/TermsConditions/checkBox'
import { useOpenProductModal } from 'hooks/useOpenProductModal'
import { useOpenQRMProduct } from 'featureSliced/shared/hooks/useOpenProductModal'

type CartProps = {
  products?: IOrderProduct[]
  setIsPaymentAvailable?: (value: boolean) => void
  children?: any
}

export type IPosition = {
  x: number
  y: number
}

export const Cart: FC<CartProps> = ({
  products,
  setIsPaymentAvailable,
  children,
}) => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const openCCProductModal = useOpenProductModal()
  const openQRMProductModal = useOpenQRMProduct()
  const openProductModal = (product: IProduct, categoryName: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      openQRMProductModal(product, categoryName)
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      openCCProductModal(product, categoryName)
    }
  }

  // Store
  const { paymentVariant, loginAvailable } = useMySelector(
    (state) => state.app.menu
  )
  const { discardOneSelectedProduct } = SelectedProductsSlice.actions

  // State
  const [selectedProduct, setSelectedProduct] = useState<IProduct>()
  const [position, setPosition] = useState<IPosition | null>(null)
  const [isUserAuth, setIsUserAuth] = useState<boolean>(true)
  const [isAVGConfirmed, setIsAVGConfirmed] = useState<boolean>(false)

  // useEffects
  useEffect(() => {
    // if (paymentVariant === 1 && location.pathname === '/payment') {
    if (location.pathname === '/payment') {
      checkIsAuth()
    }
  }, [paymentVariant])

  useEffect(() => {
    if (isUserAuth) {
      setIsPaymentAvailable?.(true)
    } else if (isAVGConfirmed) {
      setIsPaymentAvailable?.(true)
    } else {
      setIsPaymentAvailable?.(false)
    }
  }, [isUserAuth, isAVGConfirmed])

  // Function
  const handleProductClick = (
    product: IProduct,
    e: MouseEvent<HTMLElement>
  ) => {
    if (
      location.pathname !== '/payment' ||
      paymentVariant === 1 ||
      process.env.REACT_APP_IS_CLIC === '1'
    ) {
      setSelectedProduct(product)
      setPosition({
        x: e.pageX,
        y: e.pageY,
      })
    }
  }

  const checkIsAuth = async () => {
    const res = await isAuth()
    setIsUserAuth(res)
  }

  const openSignupModal = () => {
    openModal({
      id: 'AUTH_MODAL',
      title: '',
      components: [
        () => <AuthModal onLogin={checkIsAuth} initialPage="signup" />,
      ],
    })
  }

  return (
    <>
      <TableDiv>{t('order.yourOrder')}</TableDiv>
      <PaymentProductsContainer>
        <RenderIf condition={!!products?.length && !children}>
          <Products
            products={products as IOrderProduct[]}
            onProductClick={handleProductClick}
          />
        </RenderIf>
        <RenderIf condition={!!products?.length && children}>
          {children}
        </RenderIf>
      </PaymentProductsContainer>

      {/* TODO: move to separate component. also used in Split  */}
      <RenderIf condition={!isUserAuth && location.pathname === '/payment'}>
        <SubFooter>
          <CheckboxContainer>
            <TermsAndConditionsCheckbox
              isChecked={isAVGConfirmed}
              handleCheck={setIsAVGConfirmed}
            />
          </CheckboxContainer>
        </SubFooter>
        <RenderIf condition={loginAvailable}>
          <SubFooter onClick={openSignupModal}>
            <SignUpButton>
              <span>{t('scanner.orJust')}</span>
              <SignUpDecorated>
                {t('auth.signUp')?.toLowerCase()}*
              </SignUpDecorated>
            </SignUpButton>
            <SignUpDescription>{t('auth.signUpAdvantages')}</SignUpDescription>
          </SubFooter>
        </RenderIf>
      </RenderIf>
      <RenderIf condition={!!position}>
        <ContextMenu
          position={position as IPosition}
          discardPosition={() => setPosition(null)}
          items={contextMenuItems(
            t,
            selectedProduct as IProduct,
            (product: IProduct) =>
              openProductModal(product, t('order.updateOrder')),
            (productId: string) =>
              dispatch(discardOneSelectedProduct(productId)),
            !selectedProduct?.menuItems &&
              (location.pathname !== '/payment' ||
                paymentVariant === 1 ||
                process.env.REACT_APP_IS_CLIC === '1')
          )}
        />
      </RenderIf>
    </>
  )
}

const contextMenuItems = (
  t: any,
  selectedProduct: IProduct,
  handleEditClick: (product: IProduct) => void,
  handleRemoveProduct: (id: string) => void,
  isEditEnabled: boolean
) => [
  ...(isEditEnabled
    ? [
        {
          component: (
            <>
              <img src={basket} alt="Edit" />
              {t('buttons.edit')}
            </>
          ),
          onClick: () => handleEditClick(selectedProduct),
        },
      ]
    : []),
  {
    component: (
      <>
        <img src={trashCan} alt="Remove" />
        {t('buttons.remove')}
      </>
    ),
    onClick: () => {
      handleRemoveProduct(selectedProduct.uniqueId as string)
    },
  },
]
