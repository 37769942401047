import { FC, useEffect, useState } from 'react'
import qrcode from 'qrcode'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { errorHandler } from '../../../components/ErrorHandler'

import { RefillCardModal } from './RefillCard'
import { formatGuid } from '../../../utils/formatGuid'
import { CardType } from '../../../api/clickCollect/types'
import { convertToPrice } from '../../../utils/convertToPrice'
import { clickCollectGET } from '../../../api/clickCollect/GET'
import { useTranslations } from '../../../hooks/useTranslations'
import { handlePromiseAll } from '../../../utils/handlePromiseAll'
import { ReactComponent as OrdersIcon } from '../../../assets/profile/orders.svg'
import { ReactComponent as PaymentVariant } from '../../../assets/profile/credit_card.svg'

import {
  CardTitle,
  RefillButtonContainer,
  CardContainer,
  CardHeader,
  CardHoldersName,
  CardSaldoPointContainer,
  CardSaldoPointItem,
  CardQrCodeContainer,
} from './styled'
import { useOpenModal } from 'hooks/useOpenModal'
import { useMySelector } from 'hooks/useMySelector'
import { RenderIf } from 'components/RenderIf'
import { Spinner } from 'featureSliced/entities/Spinner'
import { Button } from 'featureSliced/entities/Button'
import { IconStroked } from 'components/IconsContainers/styled'

type CardProps = {
  moveToOrders?: () => void
  isWallet?: boolean
  setIsCardLoading?: (value: boolean) => void
}

export const Card: FC<CardProps> = ({
  moveToOrders,
  setIsCardLoading,
  isWallet,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { colour } = useMySelector((state) => state.app.interfaceStyles)
  const [searchParams] = useSearchParams()
  const isIOS = /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase()
  )

  const walletCardId = searchParams.get('walletCardId')

  // Store
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { DatatransActivated } = useMySelector((state) => {
    return state.app.companyData
  })

  // State
  const [cardData, setCardData] = useState<CardType>()
  const [qrUrl, setQrUrl] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRefillEnabled, setIsRefillEnabled] = useState<boolean>(false)
  const [feeDetails, setFeeDetails] = useState(null)

  // useEffect
  useEffect(() => {
    if (!isWallet) {
      getCustomerCard()
    }
  }, [])

  useEffect(() => {
    if (isWallet && walletCardId) {
      getCustomerCardWallet()
    }
  }, [walletCardId])

  useEffect(() => {
    if (cardData?.eanCode) {
      generateQRCode(cardData?.eanCode)
    }
  }, [cardData])

  // Functions
  const getCustomerCard = async () => {
    try {
      setIsLoading(true)
      const [card, details] = await handlePromiseAll([
        clickCollectGET.customerCard,
        clickCollectGET.cardDetails,
      ])
      setCardData(card)
      setIsRefillEnabled(!!details?.loadId)
      setFeeDetails(details?.settings)
    } catch (e: any) {
      errorHandler(e?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getCustomerCardWallet = async () => {
    try {
      setIsCardLoading ? setIsCardLoading(true) : setIsLoading(true)
      const card = await clickCollectGET.customerCard(
        formatGuid(walletCardId || '')
      )
      setCardData(card)
    } catch (e: any) {
      errorHandler(e?.message)
    } finally {
      setIsCardLoading ? setIsCardLoading(false) : setIsLoading(false)
    }
  }

  const generateQRCode = (value: string) => {
    qrcode.toDataURL(value, (_err: any, url: string) => setQrUrl(url))
  }

  const openRefillModal = () => {
    if (DatatransActivated) {
      openModal({
        id: 'REFILL_CARD',
        title: t('profile.loadCardFor'),
        components: [
          ({ closeThisModal }) => (
            <RefillCardModal
              feeDetailsProps={feeDetails}
              onClose={() => {
                toast.success('successfully refilled')
                getCustomerCard()
                closeThisModal()
              }}
            />
          ),
        ],
      })
    }
  }

  return (
    <>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <CardTitle>{t('profile.creditCard')}</CardTitle>
        <CardContainer isIOS={isIOS}>
          <CardHeader isIOS={isIOS}>
            <RenderIf condition={Boolean(isIOS)}>
              <p />
              <CardSaldoPointItem isIOS={isIOS}>
                <p>KARTENNUMMER</p>
                <p>{cardData?.cardNumber}</p>
              </CardSaldoPointItem>
            </RenderIf>
          </CardHeader>
          <CardHoldersName isIOS={isIOS}>
            <p>{cardData?.cardName}</p>
          </CardHoldersName>
          <CardSaldoPointContainer isIOS={isIOS}>
            <CardSaldoPointItem isIOS={isIOS}>
              <p>{t('profile.saldo')}</p>
              <p>
                {Currency ? `${Currency} ` : ''}
                {convertToPrice(cardData?.total || 0)}
              </p>
            </CardSaldoPointItem>
            <CardSaldoPointItem isIOS={isIOS} isBonuses>
              <p>{t('profile.bonuses')}</p>
              <p>{cardData?.points || 0}</p>
            </CardSaldoPointItem>
          </CardSaldoPointContainer>
          <CardQrCodeContainer isIOS={isIOS}>
            <img src={qrUrl} alt="qr-code" />
          </CardQrCodeContainer>
        </CardContainer>
        <RefillButtonContainer>
          <RenderIf condition={!!moveToOrders}>
            <Button buttonType="stroke" width="100%" onClick={moveToOrders}>
              <IconStroked color={colour} style={{ marginLeft: '-15px' }}>
                <OrdersIcon />
              </IconStroked>
              {t('profile.orders')}
            </Button>
          </RenderIf>
          <RenderIf
            condition={
              !isWallet &&
              isRefillEnabled &&
              DatatransActivated &&
              navigator.onLine
            }
          >
            <Button buttonType="common" width="100%" onClick={openRefillModal}>
              <IconStroked color="#fff" style={{ marginLeft: '-10px' }}>
                <PaymentVariant />
              </IconStroked>
              {t('profile.refill')}
            </Button>
          </RenderIf>
        </RefillButtonContainer>
      </RenderIf>
    </>
  )
}
