import { FC } from 'react'

import clickAndCollectLogo from 'assets/prompt/clickAndCollect.svg'
import qrMenuLogo from 'assets/prompt/qrMenu.svg'
import { SpinnerStyled, SpinnerImage } from './styled'
import { useMySelector } from 'hooks/useMySelector'

type SpinnerProps = {
  type?: 'default' | 'alternate' | 'transparent'
}

const backgroundColors = {
  light: {
    default: '#434343',
    alternate: '#fafafa',
    transparent: 'transparent',
  },
  dark: {
    default: '#434343',
    alternate: '#16161c',
    transparent: 'transparent',
  },
}

const positions = {
  default: 'static',
  alternate: 'static',
  transparent: 'absolute',
}

export const Spinner: FC<SpinnerProps> = ({ type = 'default' }) => {
  const { logoImage, appTheme } = useMySelector(
    (state) => state.app.interfaceStyles
  )

  const getimageSrc = () => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      return logoImage || qrMenuLogo
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      return logoImage || clickAndCollectLogo
    }
  }

  return (
    <SpinnerStyled
      background={backgroundColors?.[appTheme]?.[type]}
      position={positions?.[type]}
    >
      <SpinnerImage
        src={getimageSrc()}
        height="300"
        width="300"
        alt="Loading..."
      />
    </SpinnerStyled>
  )
}
