import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { useSearchParams } from 'react-router-dom'
import { errorHandler } from '../components/ErrorHandler'
import { AppSlice } from 'store/reducers/AppReducer'
import { getAppData } from 'store/reducers/AppReducer/actionCreator'
import { getCategoriesData } from 'store/reducers/CategoriesReducer/actionCreator'
import {
  detectDefaultLanguage,
  getAllergens,
} from 'store/reducers/CommonReducer/actionCreator'
import { getRestaurants } from 'store/reducers/RestaurantsReducer/actionCreator'
import { getSpecialPrices } from 'store/reducers/SpecialsReducer/actionCreator'
import { initTranslations } from 'store/reducers/TranslationsReducer/actionCreator'
import { handleLocalStorage } from '../utils/handleLocalStorage'
import history from '../utils/history'
import { setDefaultDocumentName } from '../utils/setDefaultDocumentName'
import { useMyDispatch } from './useMyDispatch'
import { useWishlist } from './useWishlist'
import enableNotifications from '../utils/webPush/initializeState'
import { v4 as uuidv4 } from 'uuid'
import { formatGuid } from '../utils/formatGuid'
import { Auth } from '@aws-amplify/auth'
import { loadTableData } from 'store/reducers/QRMenuTableReducer/actionCreator'
import { useTableStatus } from './useTableStatus'

type StartAppType = () => void
type InitialLoadingType = () => Promise<void>
type LoadMenuDataType = () => Promise<void>

export const useStartApp = (
  setIsLoading: (value: boolean) => void
): [StartAppType, InitialLoadingType, LoadMenuDataType] => {
  //
  // Hooks
  const dispatch = useMyDispatch()
  const initWishlist = useWishlist()
  const { checkTableStatus } = useTableStatus()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setIsProductsLoaded } = AppSlice.actions

  // MAIN FUNC
  const startApp = () => {
    setDefaultDocumentName()
    dispatch(initTranslations())
    if (location.pathname !== '/welcome' && location.pathname !== '/scanner') {
      initialLoading()
    }
  }

  // Functions
  const initialLoading = async () => {
    try {
      dispatch(showLoading())
      await navigator.serviceWorker?.register('/sw.js')
      const { guid, registerId } = saveAndRemoveSearchParams()
      loadAppData(guid, registerId)
      enableNotifications()
    } catch (e) {
      errorHandler(e)
    }
  }

  const loadAppData = (guid: string, registerId: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      if (guid && registerId) {
        loadQrMenu()
      } else if (
        location.pathname !== '/reservation' &&
        location.pathname !== '/booking'
      ) {
        history.replace('/welcome')
      }
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      if (guid) {
        loadClickCollect(registerId)
      } else {
        history.replace('/welcome')
      }
    }
  }

  const loadQrMenu = async () => {
    try {
      await Promise.all([
        initWishlist(),
        dispatch(detectDefaultLanguage()),
        loadMenuData(),
        dispatch(getRestaurants({})),
      ])
      dispatch(getAllergens())
    } catch (e) {
      setIsLoading(false)
      errorHandler(e)
    }
  }

  const loadClickCollect = async (registerId: string) => {
    try {
      await Promise.all([
        initWishlist(),
        dispatch(getAppData()),
        dispatch(detectDefaultLanguage()),
        dispatch(getRestaurants({})),
      ])
      if (registerId) {
        await loadMenuData() // loadMenuData contains getAppData
      }
      dispatch(getAllergens())
    } catch (e) {
      setIsLoading(false)
      errorHandler(e)
    }
  }

  const loadMenuData = async () => {
    try {
      const tableId = searchParams.get('table')
      await Promise.all([
        dispatch(getAppData()),
        dispatch(getCategoriesData()),
        dispatch(getSpecialPrices()),
      ])
      dispatch(hideLoading())
      // TODO: concider moving setIsProductsLoaded to corresponding AC
      dispatch(setIsProductsLoaded(true))
      checkTableStatus()
    } catch (e) {
      setIsLoading(false)
      errorHandler(e)
    }
  }

  // helpers
  const saveAndRemoveSearchParams = () => {
    const guid = searchParams.get('guid')
    const registerId = searchParams.get('register')

    const sessionId = handleLocalStorage('sessionId')
    if (!sessionId) {
      handleLocalStorage({ sessionId: uuidv4() })
    }

    if (guid) {
      const prevGUID = handleLocalStorage('guid')
      const newGuid = formatGuid(guid)
      if (prevGUID !== newGuid) {
        handleLocalStorage({ guid: newGuid })
        window.localStorage.removeItem('registerId')
        window.localStorage.removeItem('pickUpDelay')
        window.localStorage.removeItem('pickUpTime')
        window.localStorage.removeItem('userName')
        Auth.signOut()
      }
      searchParams.delete('guid')
    }
    if (registerId) {
      handleLocalStorage({ registerId })
      searchParams.delete('register')
    }
    setSearchParams(searchParams)
    return handleLocalStorage(['guid', 'registerId'])
  }

  // RETURN
  return [startApp, initialLoading, loadMenuData]
}
