import { clickCollectInstance } from '.'
import moment from 'moment'
import { CCpostPathes } from '../ApiPOSTPathes'
import { getUserData } from '../../utils/getUserData'
import { ProductToPay } from '../../sections/Payment/types'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import {
  CreateOrderType,
  CreateOrderTypeCC,
  RefillCompleteType,
  RefillRequestType,
} from './types'
import { subscribeForNotifications } from '../../utils/webPush/initializeState'
import { isSupported } from 'firebase/messaging'
import { WalletPathes } from '../ApiGETPathes'

const withToken = true
export class clickCollectPOST {
  public static async createCustomerCard() {
    const guid = handleLocalStorage('guid')
    const res = await clickCollectInstance.post(
      CCpostPathes.CREATE_CARD,
      {
        companyId: guid,
      },
      withToken
    )
    return res
  }

  public static async transferCustomerCard(
    loyaltyNumber: string,
    accessCode: string,
    lastTransaction: string
  ) {
    const guid = handleLocalStorage('guid')
    const res = await clickCollectInstance.post(
      CCpostPathes.TRANSFER_CARD,
      {
        companyId: guid,
        cardNumber: loyaltyNumber,
        accessCode,
        lastTransaction,
      },
      withToken
    )
    return res
  }

  public static async refillCardRequest(
    amount: number,
    useNewCard: boolean,
    updateActiveCard: boolean
  ) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const res: RefillRequestType = await clickCollectInstance.post(
      CCpostPathes.REQUEST_CARD_REFILL,
      {
        companyId: guid,
        registerId,
        amount,
        useNewCard,
        updateActiveCard,
      },
      withToken
    )
    return res
  }

  public static async refillCardRequestWallet(amount: number, cardId: string) {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const result: any = await clickCollectInstance.post(
      WalletPathes.START_CARD_REFILL,
      {
        companyId: guid,
        registerId,
        amount,
        cardId,
      }
    )
    return result
  }

  public static async refillCardComplete(
    transactionId: string | null = null,
    orderGuid: string | null = null
  ) {
    const guid = handleLocalStorage('guid')
    const res: RefillCompleteType = await clickCollectInstance.post(
      CCpostPathes.COMPLETE_CARD_REFILL,
      {
        companyId: guid,
        transactionId,
        orderGuid,
      },
      withToken
    )
    return !!res.message && !!res.receiptNr
  }

  public static async refillCardCompleteWallet(
    transactionId: string | null,
    orderGuid: string | null,
    cardId: string | null
  ) {
    const guid = handleLocalStorage('guid')
    const res: RefillCompleteType = await clickCollectInstance.post(
      WalletPathes.COMPLETE_CARD_REFILL,
      {
        companyId: guid,
        transactionId,
        orderGuid,
        cardId,
      }
    )
    return !!res.message && !!res.receiptNr
  }

  public static async paymentComplete(
    transactionId: string,
    orderGUID: string
  ) {
    const result: any = await clickCollectInstance.post(
      CCpostPathes.COMPLETE_ORDER_PAYMENT,
      {
        transactionId,
        orderGUID,
      }
    )
    return result
  }

  public static async createOrder(
    choosenProducts?: ProductToPay[],
    useNewCard = false,
    updateActiveCard = false
  ) {
    const {
      guid,
      registerId,
      clickCollectName,
      clickCollectPhone,
      pickUpTime,
    } = handleLocalStorage([
      'guid',
      'registerId',
      'clickCollectName',
      'clickCollectPhone',
      'pickUpTime',
    ])
    const sessionId = handleLocalStorage('sessionId')
    const pickupTime = pickUpTime || moment().utc().format()
    const currentUserInfo = await getUserData()
    const result: CreateOrderTypeCC = await clickCollectInstance.post(
      CCpostPathes.CREATE_ORDER,
      {
        companyId: guid,
        registerId,
        customerInfo: {
          name: clickCollectName,
          phoneNumber: clickCollectPhone,
          sessionId,
          userId: currentUserInfo.sub,
        },
        cardId:
          JSON.parse(
            currentUserInfo?.attributes?.['custom:card_collection'] || 0
          )?.[guid?.toLowerCase()] || 0,
        tipsPercentage: 0,
        orderItems: choosenProducts,
        pickupTime,
        useNewCard,
        updateActiveCard,
      },
      withToken
    )

    const customerPayload = {
      customerName: clickCollectName,
      phoneNumber: clickCollectPhone,
    }
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser && 'Notification' in window) {
      const currentPermission = window.Notification.permission
      if (currentPermission === 'granted') {
        await subscribeForNotifications(customerPayload)
      }
    }

    return result
  }
}
