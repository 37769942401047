import { FC, MouseEvent, useEffect, useState } from 'react'

import { useOpenNote } from 'hooks/useOpenNote'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'
import { ICartProduct } from 'store/generalTypes'
import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'

import { convertToPrice } from 'utils/convertToPrice'
import { roundValueTo } from 'utils/roundValueTo'
import { getPrice } from 'utils/getProductPrice'

import { RenderIf } from 'components/RenderIf'
import { CartProductImage } from './CartProductImage'
import { AgeProtection } from './AgeProtection'

import { ReactComponent as NoteIcon } from 'assets/notes/small_note.svg'
import { IconStroked } from 'components/IconsContainers/styled'

import {
  IncludedItems,
  KitchenItem,
  KitchenItemContainer,
  Name,
  Note,
  Price,
  ProductStyled,
  SecondaryImage,
} from './styled'

type CartProductProps = {
  product: ICartProduct
  isSelected?: boolean
  handleProductClick?: (
    productId: number,
    e: MouseEvent<HTMLElement>,
    product: ICartProduct
  ) => void
}

export const CartProduct: FC<CartProductProps> = ({
  product,
  isSelected,
  handleProductClick,
}) => {
  // Hooks
  const t = useTranslations()
  const openNote = useOpenNote()

  // Store
  const productsList = useMySelector((state) => state.products.productList)
  const currency = useMySelector((state) => state.app.companyData).Currency
  const paymentVariant = useMySelector((state) => state.app.menu).paymentVariant
  const notesAvailable = useMySelector((state) => state.app.menu).notesAvailable

  const productFull =
    productsList?.find((productL) => productL.id === product?.productId) ||
    productsList
      ?.flatMap((productL) => productL.subTemplateItems || [])
      .find((stProduct) => stProduct.id === product?.productId)

  // State
  const [productsNotes, setProductsNotes] = useState<{
    [key: number]: string
  }>({})

  // useEffects
  useEffect(() => {
    setProductsNotes(handleLocalStorage('products_notes'))
  }, [])

  // Functions
  const handleNoteClick = (e: any) => {
    if (notesAvailable === 1) {
      e.stopPropagation()
      openNote(
        product?.productId,
        setProductsNotes,
        !(location.pathname === '/payment' && paymentVariant === 2)
      )
    }
  }

  const getMenuItems = () => {
    if (typeof product?.menuItems === 'string') {
      return JSON.parse(product?.menuItems || '[]')
    }
    if (Array.isArray(product?.menuItems)) {
      return product?.menuItems
    }
  }

  const getIsMenuItemsShown = () => {
    if (typeof product?.menuItems === 'string') {
      return Boolean(getMenuItems()?.length)
    }
    if (Array.isArray(product?.menuItems)) {
      return Boolean(getMenuItems()?.length)
    }
    return false
  }

  // TODO: duplicates. move to helpers
  const getIncludedItemPrice = (price = 0, count = 0) => {
    if (price && count) {
      return `${count} x ${convertToPrice(price)} ${currency || ''}`
    }
    if (!price && count) {
      return `x ${count}`
    }
    if (price && !count) {
      return `${convertToPrice(price)} ${currency || ''}`
    }
    if (!price && !count) {
      return ''
    }
    return ''
  }

  const kitchenInfoParsed = JSON.parse(product?.kitchenInfo || '{}')
  const kitchenFreeInfoParsed = JSON.parse(product?.kitchenFreeInfo || '{}')

  return (
    <ProductStyled
      id={`product_${product?.productId}`}
      isSelected={isSelected}
      onClick={(e) => handleProductClick?.(product?.productId, e, product)}
    >
      <CartProductImage product={productFull} />
      <Name>
        <p>{productFull?.title}</p>
      </Name>
      <Note>
        <RenderIf condition={!!productsNotes?.[product?.productId]}>
          <p>{productsNotes?.[product?.productId]}</p>
        </RenderIf>
      </Note>

      <AgeProtection product={productFull} />

      {/* Notes */}
      <RenderIf
        condition={
          notesAvailable === 1 &&
          !(location.pathname === '/payment' && paymentVariant === 2)
        }
      >
        <SecondaryImage onClick={handleNoteClick}>
          <IconStroked>
            <NoteIcon />
          </IconStroked>
        </SecondaryImage>
      </RenderIf>

      {/* Price */}
      <RenderIf condition={Boolean(getPrice(product))}>
        <Price>
          <span>{product.count}</span>
          &thinsp;
          <span>x</span>
          &thinsp;
          <span>
            {convertToPrice(roundValueTo(product.count * product.price))}
          </span>
          &thinsp;
          <span>{currency || ''}</span>
        </Price>
      </RenderIf>

      {/* NO Price */}
      <RenderIf condition={Boolean(!getPrice(product))}>
        <Price>
          <span>x {product.count}</span>
        </Price>
      </RenderIf>

      {/* Additional info */}
      <RenderIf
        condition={
          !!product?.menuItems?.length ||
          !!kitchenInfoParsed?.length ||
          !!kitchenFreeInfoParsed?.length
        }
      >
        <IncludedItems>
          <RenderIf condition={!!kitchenInfoParsed?.length}>
            <KitchenItemContainer>
              {Object.values(kitchenInfoParsed)?.map((el) => (
                <KitchenItem key={`kitchenInfo_${el}`}>
                  {el as string}
                </KitchenItem>
              ))}
            </KitchenItemContainer>
          </RenderIf>

          <RenderIf condition={!!kitchenFreeInfoParsed?.length}>
            <KitchenItemContainer>
              {Object.values(kitchenFreeInfoParsed)?.map((el) => (
                <RenderIf key={`kitchenFreeInfo_${el}`} condition={Boolean(el)}>
                  <KitchenItem>{el as string}</KitchenItem>
                </RenderIf>
              ))}
            </KitchenItemContainer>
          </RenderIf>

          <RenderIf condition={getIsMenuItemsShown()}>
            {getMenuItems()?.map((currentItem: any) => (
              // <p
              //   key={`includedItem2_${currentItem.productId}_${currentItem.recordGuid}`}
              // >{`${currentItem.name} (${
              //   product?.count
              // } x ${getIncludedItemPrice(currentItem.price)})`}</p>
              <p
                key={`includedItem2_${currentItem.productId}_${currentItem.recordGuid}`}
              >{`${currentItem.name} (${getIncludedItemPrice(
                currentItem.price,
                product?.count
              )})`}</p>
            ))}
          </RenderIf>
        </IncludedItems>
      </RenderIf>
    </ProductStyled>
  )
}
