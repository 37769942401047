import { FC, useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Card } from '../../../components/Card'
import { qrMenuPOST } from '../../../api/qrMenu/POST'
import { DataTrans } from '../../../components/Checkout/DataTrans'
import { useTranslations } from 'hooks/useTranslations'
import { RenderIf } from 'components/RenderIf'
import { Spinner } from 'featureSliced/entities/Spinner'
import { Button } from 'featureSliced/entities/Button'

type CardDataType = {
  cardNumber: string
  dueDate: string
}

export const Payment: FC = () => {
  const t = useTranslations()

  const [isLoading, setIsLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [cardData, setCardData] = useState<CardDataType>({
    cardNumber: '',
    dueDate: '',
  })

  useEffect(() => {
    loadCards()
  }, [])

  const loadCards = async () => {
    try {
      setIsLoading(true)
      const currentUserInfo = await Auth.currentUserInfo()
      const attributes = currentUserInfo?.attributes
      if (attributes) {
        if (attributes?.['custom:active_card_token']) {
          const originalData = JSON.parse(
            attributes?.['custom:active_card_token']
          )
          setCardData({
            cardNumber: originalData.masked,
            dueDate: `${originalData.expiryMonth}/${originalData.expiryYear}`,
          })
        }
      }
    } catch (e) {
      console.log('Error:', e)
    } finally {
      setIsLoading(false)
    }
  }

  const requestTransactionIdForNewCard = async () => {
    setIsButtonLoading(true)
    const { transactionId } = await qrMenuPOST.createNewPaymentVariant()
    setPaymentId(transactionId)
  }

  return (
    <>
      <RenderIf condition={isLoading}>
        <Spinner type="alternate" />
      </RenderIf>
      <RenderIf condition={!!paymentId}>
        <DataTrans transactionId={paymentId as string} />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <Card {...cardData} />
        <br />
        <RenderIf condition={navigator.onLine}>
          <Button
            buttonType="common"
            width="100%"
            isLoading={isButtonLoading}
            onClick={requestTransactionIdForNewCard}
          >
            {t('profile.addNewPaymentMethod')}
          </Button>
        </RenderIf>
      </RenderIf>
    </>
  )
}
