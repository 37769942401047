import { FC } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import fire from '../../../assets/fire.svg'
import {
  Description,
  DescriptionContainer,
  Percentage,
  PercentageP,
  SpecialItem,
  SpecialItemImage,
  Title,
  TitleContainer,
} from './styled'
import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { IDiscount } from '../../../store/reducers/SpecialsReducer/types'

type DiscountType = {
  discount: IDiscount
  handleProductClick: (product: IProduct) => void
}

export const Discount: FC<DiscountType> = ({
  discount,
  handleProductClick,
}) => {
  const { productList: products } = useMySelector((state) => state.products)
  const currentProduct = products.find(
    (product) => product.id === discount.productId
  )
  const onProductClick = () => {
    if (currentProduct) {
      handleProductClick(currentProduct)
    }
  }
  return (
    <RenderIf condition={!!currentProduct}>
      <SpecialItem onClick={onProductClick}>
        <SpecialItemImage src={currentProduct?.imageUrl} alt="Product" />
        <div>
          <Percentage>
            <PercentageP>{discount.discountPercentage}</PercentageP>
            <img src={fire} alt="%" />
          </Percentage>
          <TitleContainer>
            <Title>{currentProduct?.title}</Title>
          </TitleContainer>
        </div>
        <DescriptionContainer>
          <Description>{discount.description}</Description>
        </DescriptionContainer>
      </SpecialItem>
    </RenderIf>
  )
}
