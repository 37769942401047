import styled from 'styled-components'

type NotificationsProps = {
  counter?: string | number
}

export const MainScreenControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const SidebarButtonContainer = styled.div`
  padding: 5vw;
`

export const NotificationsButtonContainer = styled(
  SidebarButtonContainer
)<NotificationsProps>`
  color: #fff;
  &::after {
    content: ${({ counter }) => (counter ? `'${counter}'` : 'unset')};

    background: red;
    position: absolute;
    z-index: 0;

    height: 18px;
    width: 18px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 4vw;
    right: 4.5vw;
    font-size: 12px;
    font-weight: bold;
  }
`
