import { FC, useState } from 'react'

import { ProductImage } from './ProductImage'
import { TitleAndPrice } from './TitleAndPrice'
import { UnitSellCodePrice } from './UnitSellCodePrice'
import { KitchenInfo } from './KitchenInfo'
import { Allergens } from './Allergens'
import { Description } from './Description'

import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { KitchenInfoItemType } from '../types'

import { ProductMainContainer } from './styled'

type CPCProps = {
  product: IProduct
  currentAmount?: number
  kitchenInfoState: [
    KitchenInfoItemType,
    (value: (value: KitchenInfoItemType) => any) => void
  ]
}

export const CommonProductComponent: FC<CPCProps> = ({
  product,
  currentAmount = 1,
  kitchenInfoState,
}) => {
  return (
    <>
      <ProductMainContainer>
        <ProductImage product={product} />
        <TitleAndPrice product={product} currentAmount={currentAmount} />
        <UnitSellCodePrice product={product} currentAmount={currentAmount} />
        <KitchenInfo
          kitchenInfo={product.kitchenInfo}
          kitchenInfoState={kitchenInfoState}
        />
        <Allergens product={product} />
        <Description note={product.note} />
      </ProductMainContainer>
    </>
  )
}
