import { FooterStyled } from 'components/FooterStyled/styled'
import styled from 'styled-components'

export const BucketContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 70px;
  &::after {
    content: unset;
  }
`

export const BucketProductsContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.MainBackGround};
  padding: 0;
`

export const BucketFooter = styled(FooterStyled)`
  position: sticky;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  top: env(safe-area-inset-bottom);
`

export const BucketButtonContainer = styled.div`
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
`

export const Total = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &::after {
    content: unset;
  }
  span {
    line-height: 30px;
    font-size: 17px;
    font-weight: 500;

    color: ${({ theme }) => theme.FontColor};
  }
  span:last-child {
    color: ${({ theme }) => theme.colour};
  }
`
