import { FC } from 'react'
import { AGBModal } from './AGB'
import { TermsConditions } from '.'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'
import { CheckBox } from 'featureSliced/entities/CheckBox'

type TermsAndConditionsCheckboxProps = {
  isChecked: boolean
  handleCheck: (value: boolean) => void
}

export const TermsAndConditionsCheckbox: FC<
  TermsAndConditionsCheckboxProps
> = ({ isChecked, handleCheck }) => {
  const { CompanyName } = useMySelector((state) => state.app.companyData)
  const openModal = useOpenModal()

  const openAGB = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'AGB_MODAL',
      title: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
      components: [() => <AGBModal />],
    })
  }

  const openTermsAndConditions = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'TERMS_AND_CONDITIONS',
      title: 'Terms and Conditions',
      components: [() => <TermsConditions />],
    })
  }

  return (
    <CheckBox
      isChecked={isChecked}
      onClick={handleCheck}
      // TODO: translations
      label={
        <p>
          Mit Ihrer Bestellung stimmen Sie{' '}
          <b aria-hidden="true" onClick={openAGB}>
            den AGB
          </b>{' '}
          zu und bestatigen die{' '}
          <b aria-hidden="true" onClick={openTermsAndConditions}>
            Datenschutzerklarung
          </b>{' '}
          von {CompanyName || 'Muster'} zur Kenntnis genommen zu haben.
        </p>
      }
    />
  )
}
