import { RenderIf } from 'components/RenderIf'
import MainScreenClickCollect from 'sections/MainScreen'
import { MainScreen } from './index'

export const MainScreenHandler = () => {
  return (
    <>
      {/* NOTE: temporary solution for click&collect to work with an old Main screen */}
      {/* TODO: get rid of MainScreenClickCollect once MainScreen is readyqq */}
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <MainScreen />
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <MainScreenClickCollect />
      </RenderIf>
    </>
  )
}
