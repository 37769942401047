import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { Button } from 'featureSliced/entities/Button'
import { useTranslations } from 'hooks/useTranslations'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { GridDiv } from 'utils/gridDiv'
import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'
import { useMySelector } from 'hooks/useMySelector'

type ASPFProps = {
  product: IProduct
  moveToNext: () => void
}

export const AddSpecialProductFooter: FC<ASPFProps> = ({
  product,
  moveToNext,
}) => {
  // Hooks
  const t = useTranslations()
  const tableStatus = useMySelector((state) => state.qrMenuTable.tableStatus)
  const bookingVariant = useMySelector((state) => state.app.menu.bookingVariant)

  return (
    <RenderIf
      condition={
        navigator.onLine &&
        (!!product.menuItems?.length || !!product.subTemplateItems?.length) &&
        bookingVariant === 2 &&
        tableStatus !== TableStatuses.BUSY &&
        !product.soldOut
      }
    >
      <GridDiv gc="1 / -1">
        <Button buttonType="common" width="100%" onClick={moveToNext}>
          {t('products.compose')}
        </Button>
      </GridDiv>
    </RenderIf>
  )
}
