import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { useTranslations } from '../../../hooks/useTranslations'
import { convertToPrice } from '../../../utils/convertToPrice'
import { roundValueTo } from '../../../utils/roundValueTo'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { GridDiv } from '../../../utils/gridDiv'
import { PageType } from '../'

import { Spinner } from '../../../components/Spinner'
import { errorHandler } from '../../../components/ErrorHandler'
import { Button } from '../../../components/Button'
import { IHistoryItem } from '../../../api/qrMenu/types'

import {
  NumberText,
  MainText,
  OrderItem,
  NumberDate,
  OrderHeader,
  OrderProduct,
  OrderProducts,
  OrderProductImage,
  OrderProductTitle,
  OrderProductTotal,
  BranchPrice,
  SubText,
  Page,
  PriceContainer,
  PriceContainerItem,
  NoOrders,
  OrdersButtonsContainer,
} from './styled'
import { useSearchParams } from 'react-router-dom'
import { formatGuid } from '../../../utils/formatGuid'
import { Select } from '../../../components/Select'

type OrdersProps = {
  isWallet?: boolean
  setIsOrdersLoading?: (value: boolean) => void
}

export const Orders: FC<OrdersProps> = ({ isWallet, setIsOrdersLoading }) => {
  // Hooks
  const t = useTranslations()
  const { productList: products } = useMySelector((state) => state.products)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const [searchParams] = useSearchParams()

  const walletCardId = searchParams.get('walletCardId')

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openId, setOpenId] = useState<number | null>(null)
  const [history, setHistory] = useState<IHistoryItem[]>([])
  const [historyRange, setHistoryRange] = useState<'thisMonth' | 'lastMonth'>(
    'thisMonth'
  )
  const isClick = process.env.REACT_APP_IS_CLIC === '1'

  // useEffects
  useEffect(() => {
    if (!isWallet) {
      requestOrdersHistory()
    }
  }, [])

  useEffect(() => {
    if (isWallet && walletCardId) {
      requestOrdersHistoryWallet()
    } else {
      setIsLoading(false)
    }
  }, [walletCardId])

  // Functions

  const requestOrdersHistory = async () => {
    try {
      setIsLoading(true)
      const res = await qrMenuGET.ordersHistory()
      if (isClick) {
        setHistory(res.sort((a, b) => b.createdAt - a.createdAt))
      }
      if (!isClick) {
        setHistory(
          res
            .filter((el) => el.isCompleted)
            .sort((a, b) => b.createdAt - a.createdAt)
        )
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const requestOrdersHistoryWallet = async () => {
    try {
      setIsLoading(true)
      setIsOrdersLoading?.(true)
      const res = await qrMenuGET.ordersHistoryWallet(
        formatGuid(walletCardId || '')
      )
      setHistory(res.sort((a, b) => b.createdAt - a.createdAt))
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
      setIsOrdersLoading?.(false)
    }
  }

  const handleOpenItem = (itemId: number) => {
    if (openId === itemId) {
      setOpenId(null)
    } else {
      setOpenId(itemId)
    }
  }

  const filteredHistory = () => {
    return history.filter((el) => {
      const currentMonth = moment().month()
      const lastMonth = moment().subtract(1, 'months').month()
      const itemMonth = moment(el.createdAt).month()
      if (historyRange === 'thisMonth') {
        return currentMonth === itemMonth
      }
      if (historyRange === 'lastMonth') {
        return lastMonth === itemMonth
      }
    })
  }

  const downloadReceiptPDF =
    (receiptUrl?: string, receiptNr?: number) => () => {
      if (receiptUrl && receiptNr) {
        const tmpLink = document.createElement('a')
        tmpLink.href = receiptUrl
        tmpLink.target = '_blank'
        tmpLink.download = `#${receiptNr}.pdf`
        tmpLink.dispatchEvent(new MouseEvent('click'))
      }
    }

  const getButtonType = (rangeType: 'lastMonth' | 'thisMonth') => {
    switch (true) {
      case historyRange === rangeType && isWallet:
        return 'common'
      case historyRange === rangeType && !isWallet:
        return 'dark'
      case historyRange !== rangeType && isWallet:
      case historyRange !== rangeType && !isWallet:
      default:
        return 'gray'
    }
  }

  return (
    <>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <div style={{ height: isWallet && !history.length ? '100%' : 'unset' }}>
          <RenderIf condition={!history.length}>
            <NoOrders>{t('profile.noOrdersYet')}.</NoOrders>
          </RenderIf>
          <RenderIf condition={!!history.length}>
            <OrdersButtonsContainer>
              <Page style={{ gridColumn: '1 / -1', marginTop: 'unset' }}>
                {t('profile.orders')}
              </Page>
              <Select
                options={[
                  {
                    value: 'lastMonth',
                    label: t('order.ordersLastMonth'),
                  },
                  {
                    value: 'thisMonth',
                    label: t('order.ordersThisMonth'),
                  },
                ]}
                defaultValue={historyRange}
                onChange={(newValue) =>
                  setHistoryRange(newValue as 'lastMonth')
                }
              />
            </OrdersButtonsContainer>
            {filteredHistory().map((el) => (
              <OrderItem
                key={`order-item_${el.recordTTL}`}
                onClick={() => handleOpenItem(el.recordTTL)}
              >
                <OrderHeader>
                  <NumberDate>
                    <RenderIf condition={el.appName === 'QRMENU'}>
                      <NumberText>№ {el.receiptNr}</NumberText>
                    </RenderIf>
                    <RenderIf condition={el.appName === 'CLICKCOLLECT'}>
                      <RenderIf condition={el.orderStatus === 'TAKEN'}>
                        <NumberText isGreen>{t('profile.purchase')}</NumberText>
                        <RenderIf condition={!!el.receiptNr}>
                          <NumberText>№ {el.receiptNr}</NumberText>
                        </RenderIf>
                      </RenderIf>
                      <RenderIf condition={el.orderStatus !== 'TAKEN'}>
                        <NumberText>{t('profile.reservation')}</NumberText>
                        <RenderIf condition={!!el.orderNr}>
                          <NumberText>№ {el.orderNr}</NumberText>
                        </RenderIf>
                      </RenderIf>
                    </RenderIf>
                    <SubText>{moment(el.createdAt).format('DD MMMM')}</SubText>
                  </NumberDate>
                  <BranchPrice>
                    <MainText>{el.branchName}</MainText>
                    <PriceContainer>{`${convertToPrice(
                      el.customerCardPaymentData?.total ?? el.total
                    )} ${Currency || ''}`}</PriceContainer>
                  </BranchPrice>
                </OrderHeader>
                <RenderIf condition={openId === el.recordTTL}>
                  <OrderProducts>
                    {(
                      el.customerCardPaymentData?.orderItems ?? el.orderItems
                    ).map((item, i) => {
                      const foundProduct = products.find(
                        (p) => p.id === item.id
                      ) || {
                        id: item.id,
                        imageUrl: item.imageUrl,
                        title: item.title,
                      }
                      return (
                        <RenderIf
                          condition={!!foundProduct}
                          key={`${foundProduct?.id}_${i}`}
                        >
                          <OrderProduct>
                            <RenderIf
                              condition={Boolean(foundProduct?.imageUrl)}
                            >
                              <OrderProductImage
                                src={foundProduct?.imageUrl}
                                alt={foundProduct?.title}
                              />
                            </RenderIf>
                            <RenderIf
                              condition={Boolean(!foundProduct?.imageUrl)}
                            >
                              <div />
                            </RenderIf>
                            <OrderProductTitle>
                              {foundProduct?.title}
                            </OrderProductTitle>
                            <GridDiv gr="2 / -1" gc="2 / 3">
                              <OrderProductTotal>
                                {`${item.count} x ${convertToPrice(
                                  item.price
                                )} ${Currency || ''}`}
                              </OrderProductTotal>
                            </GridDiv>
                            <GridDiv gr="1 / -1" gc="3 / -1">
                              <PriceContainerItem>
                                {`${convertToPrice(
                                  roundValueTo(item.count * item.price)
                                )} ${Currency || ''}`}
                              </PriceContainerItem>
                            </GridDiv>
                          </OrderProduct>
                        </RenderIf>
                      )
                    })}
                  </OrderProducts>
                  <RenderIf condition={!!el.receiptUrl}>
                    <Button
                      buttonType="stroke"
                      onClick={downloadReceiptPDF(el.receiptUrl, el.receiptNr)}
                      style={{ marginBottom: 16 }}
                    >
                      {t('receipt.downloadReceipt')}
                    </Button>
                  </RenderIf>
                </RenderIf>
              </OrderItem>
            ))}
          </RenderIf>
        </div>
      </RenderIf>
    </>
  )
}
