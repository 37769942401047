import { FC, useState } from 'react'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { CommonProductComponent } from '../ComonProduct'
import { KitchenInfoItemType } from '../types'
import { AddSpecialProductFooter } from '../footers/AddSpecialProductFooter'

type AddCommonProductProps = {
  product: IProduct
  onNext: (values: any) => void
}

export const AddMenuItemsProduct: FC<AddCommonProductProps> = ({
  product,
  onNext,
}) => {
  // State
  const kitchenInfoState = useState<KitchenInfoItemType>({})

  return (
    <>
      <CommonProductComponent
        product={product}
        kitchenInfoState={kitchenInfoState}
      />
      <AddSpecialProductFooter
        product={product}
        moveToNext={() => onNext(kitchenInfoState[0])}
      />
    </>
  )
}
