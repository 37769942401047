import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import { persistor, store } from './store'
import history from './utils/history'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { initSentry } from './utils/sentry'
import './index.css'

initSentry()

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
