import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { ButtonTypeExtended } from './types'
import { Button as ButtonStyled, ButtonSpinner } from './styled'

export const Button: FC<ButtonTypeExtended> = ({
  children,
  isLoading,
  onClick,
  ...props
}) => {
  const customOnClickHandler = () => {
    if (!isLoading && onClick) {
      onClick()
    }
  }
  return (
    <ButtonStyled onClick={customOnClickHandler} {...props}>
      <RenderIf condition={!isLoading}>{children}</RenderIf>
      <RenderIf condition={!!isLoading}>
        <ButtonSpinner data-testid="BUTTON_LOADING_SPINNER" />
      </RenderIf>
    </ButtonStyled>
  )
}
