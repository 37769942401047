import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMySelector } from 'hooks/useMySelector'
import { useCompleteSaferPayQRM } from 'hooks/useCompleteSaferPayQRM'
import { useCompleteDataTransQRM } from 'hooks/useCompleteDataTransQRM'

export const QRMConfirmation = () => {
  // Hooks
  const [searchParams] = useSearchParams()
  const isAppInit = useMySelector((state) => state.app.isAppInit)
  const completeSaferPay = useCompleteSaferPayQRM()
  const completeDataTrans = useCompleteDataTransQRM()

  // vars
  const datatransTrxId = searchParams.get('datatransTrxId')
  const saferPayOrderGuid = searchParams.get('orderGuid')

  // useEffect
  useEffect(() => {
    if (datatransTrxId && !saferPayOrderGuid && isAppInit) {
      completeDataTrans()
    }
  }, [isAppInit, datatransTrxId, saferPayOrderGuid])

  useEffect(() => {
    if (!datatransTrxId && saferPayOrderGuid && isAppInit) {
      completeSaferPay()
    }
  }, [isAppInit, datatransTrxId, saferPayOrderGuid])

  return null
}
