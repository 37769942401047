import styled from 'styled-components'

export const MainScreenContainer = styled.div`
  background-color: #434343;
  background-image: ${({ theme }) => `url(${theme.backgroundImage})`};
  background-position: center top;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  right: 0;
  transition: right 0.25s;
  position: fixed;
  z-index: 3;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 20px;
  width: 100%;
  align-items: center;
`
