import { useEffect, useState, useRef, FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RenderIf } from 'components/RenderIf'

import { useMyDispatch } from 'hooks/useMyDispatch'
import { useOpenModal } from 'hooks/useOpenModal'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'

import { Spinner } from 'featureSliced/entities/Spinner'
import { Button } from 'featureSliced/entities/Button'
import { Scanner } from 'featureSliced/entities/Scanner'

import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'
import { openLink } from 'featureSliced/shared/utils/openLink'

import { getTableData } from 'store/reducers/CommonReducer/actionCreator'
import { ModalsSlice } from 'store/reducers/ModalsReducer'

import {
  BranchPage,
  BranchTitle,
  TableInputContainer,
  TablePageContainer,
  TableInput,
  NumberDiv,
  OrJustHint,
  ButtonContainer,
  TableTop,
  TableCaptionError,
} from './styled'
import { useTableStatus } from 'hooks/useTableStatus'

type TableSelectProps = {
  loadData?: (tableId?: string | number) => void
  onClose: (tableId?: string | number) => void
}

export const TableSelect: FC<TableSelectProps> = ({ loadData, onClose }) => {
  // Hooks
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const t = useTranslations()
  const { closeModal } = ModalsSlice.actions
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isTableExist, setIsTableExist] = useState(true)
  const { isUrlExtended } = useMySelector((state) => state.app.menu)
  const { checkTableStatus } = useTableStatus()

  // Store
  const { tableData } = useMySelector((state) => state.common)

  // State
  const [selectedTableId, setSelectedTableId] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  // useEffects
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.select()
    }, 0)
  }, [registerId])

  useEffect(() => {
    if (isUpdated && tableData?.id) {
      navigateToNext()
    }
  }, [tableData, isUpdated]) // eslint-disable-line

  // Functions
  const navigateToNext = async () => {
    searchParams.delete('table')
    searchParams.append('table', `${tableData.id}`)
    await setSearchParams(searchParams)
    checkTableStatus()
    if (guid && registerId && tableData.id) {
      loadData?.(tableData.id)
      onClose(tableData.id)
      dispatch(closeModal('SEARCH_PARAMS_PROMPT'))
    }
  }

  const loadTableData = async () => {
    try {
      setIsLoading(true)
      setIsTableExist(true)
      if (guid && selectedTableId) {
        await dispatch(getTableData({ caption: selectedTableId }))
      }
      setIsUpdated(true)
    } catch (e) {
      setIsTableExist(false)
    } finally {
      setIsLoading(false)
    }
  }

  const openScanner = () => {
    if (registerId) {
      openModal({
        id: 'BARCODE_SCANNER',
        title: 'Scan',
        components: [
          () => (
            <Scanner
              title={t('scanner.scanQRCode')}
              subtitle={t('scanner.pointCamera')}
              onScan={openLink}
            />
          ),
        ],
      })
    }
  }

  return (
    <BranchPage>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <TablePageContainer>
        <TableTop>
          <BranchTitle>{t('scanner.enterTableNumber')}</BranchTitle>
          <TableInputContainer>
            <NumberDiv>№</NumberDiv>
            <TableInput
              ref={inputRef}
              value={selectedTableId}
              onChange={(e) => setSelectedTableId(e.currentTarget.value)}
            />
            <Button
              buttonType="common"
              height="58px"
              width="58px"
              onClick={loadTableData}
              disabled={isLoading}
            >
              {t('buttons.ok')}
            </Button>
          </TableInputContainer>
          <TableCaptionError>
            {isTableExist ? '' : t('scanner.tableDoesNotExist')}
          </TableCaptionError>
        </TableTop>
        <RenderIf condition={Boolean(isUrlExtended)}>
          <ButtonContainer>
            <OrJustHint>{t('scanner.orJust')}</OrJustHint>
            <Button buttonType="stroke" width="60%" onClick={openScanner}>
              {t('scanner.scanQRCode')}
            </Button>
          </ButtonContainer>
        </RenderIf>
      </TablePageContainer>
    </BranchPage>
  )
}

export default TableSelect
