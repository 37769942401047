import styled from 'styled-components'
import { Button } from 'featureSliced/entities/Button'
import { GridDiv } from 'utils/gridDiv'
import { InputStyled } from 'components/Search/styled'
import { Total } from 'featureSliced/widgets/PaymentQRM/Footer/styled'

type InputContainerProps = {
  height?: string
}

type SubFooterProps = {
  isSlow: boolean
  text: string
}

export const TipsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TipsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 5px;
  margin: 10px;
`

export const TipsModalButton = styled(Button)`
  flex-direction: column;
  width: calc((100vw - 30px) / 3);
  height: calc((100vw - 30px) / 3);
`

export const TipsModalButtonPercentage = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 32px !important;
  line-height: 39px;
  color: ${({ theme }) => theme.colour};
`

export const TipsGridContainer = styled(GridDiv)`
  margin: 0 -10px;
`

export const TipsInput = styled(InputStyled)`
  text-align: end;
`

export const InputCurrency = styled.p`
  margin: 0 20px;
`

export const TotalContainer = styled.div`
  padding: 0 20px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  /* border-top: ${({ theme }) => `1px solid ${theme.ItemsBorders}`}; */
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
`

export const TipsTotal = styled(Total)`
  height: 5vw;
  margin: 10px 0;
  span {
    line-height: 5vw;
    font-size: 15px;
  }
`

export const InputTitle = styled.p`
  margin: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.FontColor};
`

export const InputContainer = styled('div')<InputContainerProps>`
  margin: 10px;
  margin-top: 0;
  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: ${({ theme }) => `1px solid ${theme.colour}`};
  height: ${({ height }) => height || '40px'};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 10px;
  overflow: hidden;
`

export const SubFooterTips = styled('div')<SubFooterProps>`
  padding: 0 20px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  position: relative;
  &::after {
    content: ${({ isSlow, text }) => (isSlow ? `'${text}'` : 'unset')};
    width: 100%;
    position: absolute;
    background-color: #de6667;
    z-index: 999;
    bottom: 100%;
    left: 0;
    min-height: 5vw;
    margin: 0;
    padding: 10px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
  }
`
