import { useSearchParams } from 'react-router-dom'
import { useMySelector } from './useMySelector'
import { qrMenuPOST } from '../api/qrMenu/POST'
import { useOpenModal } from './useOpenModal'
import { Payment } from '../sections/MainScreen/Profile/Payment'
import { ReceiptModal } from '../components/ReceiptModal'
import { useMyDispatch } from './useMyDispatch'
import { useTranslations } from './useTranslations'
import { SelectedProductsSlice } from '../store/reducers/SelectedProductsReducer'
import { Card } from '../sections/MainScreen/Profile/Card'
import { clickCollectPOST } from '../api/clickCollect/POST'
import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'

export type useCompleteDataTransType = () => void

export const useCompleteDataTransQRM = (): useCompleteDataTransType => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const [searchParams, setSearchParams] = useSearchParams()

  // Store
  const { isAppInit } = useMySelector((state) => state.app)
  const { discardSelectedProducts } = SelectedProductsSlice.actions
  const clearBucket = QRMBucketSlice.actions.clearBucket

  // Variables
  const show_card = searchParams.get('show_card')
  const datatransTrxId = searchParams.get('datatransTrxId')
  const show_payment_variant = searchParams.get('show_payment_variant')

  // Functions
  const completeDataTransQRM = async () => {
    try {
      if (isAppInit && datatransTrxId) {
        const isDefaultPayment = !show_card && !show_payment_variant
        const isCCUpdate = !show_card && show_payment_variant
        const isLoyaltyRefill = show_card && !show_payment_variant
        if (isDefaultPayment) {
          openReceiptModal()
        }
        if (isCCUpdate) {
          completeCreditCardUpdate()
        }
        if (isLoyaltyRefill) {
          completeCardRefill()
        }
      }
    } catch (e) {
      searchParams.delete('show_card')
      searchParams.delete('datatransTrxId')
      searchParams.delete('show_payment_variant')
      setSearchParams(searchParams)
    }
  }

  const completeCreditCardUpdate = async () => {
    const success = await qrMenuPOST.completeNewPaymentVariant(
      datatransTrxId as string
    )
    if (success) {
      openModal({
        id: 'USER_CREDIT_CARD',
        title: '',
        components: [() => <Payment />],
      })
    }
    searchParams.delete('show_payment_variant')
    searchParams.delete('datatransTrxId')
    setSearchParams(searchParams)
  }

  const completeCardRefill = async () => {
    const success = await clickCollectPOST.refillCardComplete(datatransTrxId)
    if (success) {
      openModal({
        id: 'USER_CARD',
        title: '',
        components: [() => <Card />],
      })
    }
    searchParams.delete('show_card')
    searchParams.delete('datatransTrxId')
    setSearchParams(searchParams)
  }

  const openReceiptModal = async () => {
    openModal({
      id: 'RECEIPT_MODAL',
      title: t('order.toMenu'),
      onClose: handleReceiptModalClose,
      components: [
        ({ closeThisModal }) => (
          <ReceiptModal
            onCloseModal={() => {
              closeThisModal()
              handleReceiptModalClose()
            }}
          />
        ),
      ],
    })
  }

  const handleReceiptModalClose = () => {
    searchParams.delete('datatransTrxId')
    searchParams.delete('table')
    setSearchParams(searchParams)
    clearLocalStorage()
    dispatch(discardSelectedProducts()) // TODO: deprecated. remove
    dispatch(clearBucket())
  }

  const clearLocalStorage = () => {
    window.localStorage.removeItem('products_notes')
  }

  return completeDataTransQRM
}
