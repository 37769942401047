import styled from 'styled-components'

type EmptyBlockProps = {
  height?: string
}

export const PaymentContainer = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.MainBackGround};
  position: fixed;
  left: 0;
  top: 0;
`

export const EmptyBlock = styled('div')<EmptyBlockProps>`
  min-height: ${({ height = '70px' }) => height};
  height: ${({ height = '70px' }) => height};
  max-height: ${({ height = '70px' }) => height};
`
