import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { getProtectedAge } from 'utils/getProtectedAge'
import { isProductProtected } from 'utils/isProductProtected'

import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { AgeProtectionP } from './styled'

type AgeProtectionType = {
  product?: IProduct
}

export const AgeProtection: FC<AgeProtectionType> = ({ product }) => {
  return (
    <RenderIf condition={isProductProtected(product)}>
      <AgeProtectionP>{getProtectedAge(product)}</AgeProtectionP>
    </RenderIf>
  )
}
