import { FC, useEffect, useState } from 'react'

import { useTranslations } from 'hooks/useTranslations'
import { useMyDispatch } from 'hooks/useMyDispatch'
import { useMySelector } from 'hooks/useMySelector'
import { convertToPrice } from 'utils/convertToPrice'
import { getPrice } from 'utils/getProductPrice'

import { Button } from 'featureSliced/entities/Button'
import { ProgressBar } from 'components/ProgressBar'
import { RenderIf } from 'components/RenderIf'

import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'
import { IProduct } from 'store/reducers/CategoriesReducer/types'

import chevronDown from 'assets/chevronDown.svg'
import {
  ItemsContainer,
  ItemsGrid,
  ItemsGridExpander,
  MainTitle,
  Item,
  ItemImageContainer,
  ItemImage,
  ItemPrice,
} from './styled'

type AddMenuItemsProps = {
  product: IProduct
  closeModal: () => void
}

export const AddMenuItems: FC<AddMenuItemsProps> = ({
  product,
  closeModal,
  ...selectedKitchenInfo
}) => {
  // Hooks
  const dispatch = useMyDispatch()
  const t = useTranslations()

  // Actions
  const addProductToBucket = QRMBucketSlice.actions.addProductToBucket

  // Store
  const { Currency } = useMySelector((state) => state.app.companyData)

  // State
  const [currentSelected, setCurrentSelected] = useState(0)
  const [selectedMenuItems, setSelectedMenuItems] = useState<any[]>([])
  const [isItemsCollapsed, setIsItemsCollapsed] = useState<boolean>(false)
  const [isCollapsedTouched, setIsCollapsedTouched] = useState<boolean>(false)

  // useEffects
  useEffect(() => {
    if (
      !isCollapsedTouched &&
      product.menuItems[currentSelected]?.items?.length > 6
    ) {
      setIsItemsCollapsed(true)
    }
  }, [product.menuItems, currentSelected])

  useEffect(() => {
    const isMaxReached =
      selectedMenuItems.filter(
        (el) => el.categoryId === product.menuItems[currentSelected].categoryId
      )?.length >= product.menuItems[currentSelected].maxCount
    if (isMaxReached) {
      orderOrNext()
    }
  }, [selectedMenuItems])

  // Functions
  const handleClick = (itemToAdd: any) => {
    setSelectedMenuItems((prevValue) => [
      ...prevValue,
      {
        ...itemToAdd,
        count: 1,
        price: getPrice(itemToAdd),
      },
    ])
  }

  const orderOrNext = () => {
    if (currentSelected + 1 < product.menuItems.length) {
      setCurrentSelected((prevValue) => prevValue + 1)
    }
    if (currentSelected + 1 === product.menuItems.length) {
      orderProduct()
    }
  }

  const orderProduct = () => {
    const { id, categoryId, unitSellCode } = product
    const sKI = JSON.stringify(selectedKitchenInfo)
    const sMI = selectedMenuItems
      ?.map((sMI) => sMI.productId)
      ?.sort((a, b) => a - b)
    const productToAdd = {
      // SORT
      uniqueId: `${id}_${sKI}_${sMI}`,
      productId: id,
      categoryId,
      count: 0, // 0 is needed for UPDAYE to work correctly
      kitchenInfo: JSON.stringify(selectedKitchenInfo || {}),
      price: getPrice(product),
      addedAsWeight: unitSellCode === 2 || unitSellCode === 3,
      menuItems: selectedMenuItems,
    }
    dispatch(addProductToBucket(productToAdd))
    closeModal()
  }

  const getSlicedArray = (arrayToSlice: any[]) => {
    if (!isCollapsedTouched && isItemsCollapsed) {
      return arrayToSlice.slice(0, 6)
    }
    return arrayToSlice
  }

  const { maxCount, minCount } = product.menuItems[currentSelected]

  return (
    <div>
      <RenderIf condition={product.menuItems.length > 1}>
        <ProgressBar
          progress={((currentSelected + 1) / product.menuItems.length) * 100}
        />
      </RenderIf>
      <MainTitle>
        {minCount === maxCount
          ? t('products.buildPerfectSpecific', {
              productName: product.title,
              count: product.menuItems[currentSelected].minCount || 1,
            })
          : t('products.buildPerfect', {
              productName: product.title,
              minCount: product.menuItems[currentSelected].minCount,
              maxCount: product.menuItems[currentSelected].maxCount,
            })}
      </MainTitle>
      <ItemsContainer>
        <ItemsGrid>
          {getSlicedArray(product.menuItems[currentSelected].items).map(
            (item: any) => (
              <Item
                key={`menu-item_${item.productId}`}
                onClick={() => handleClick(item)}
                isSelected={selectedMenuItems.find(
                  (selectedItem) => selectedItem.productId === item.productId
                )}
                count={
                  selectedMenuItems.filter(
                    (selectedItem) => selectedItem.productId === item.productId
                  )?.length
                }
              >
                <ItemImageContainer>
                  <ItemImage src={item.imageUrl} />
                </ItemImageContainer>
                <ItemPrice>{item.name}</ItemPrice>
                <ItemPrice>{`${convertToPrice(item.price)} ${
                  Currency || ''
                }`}</ItemPrice>
              </Item>
            )
          )}
        </ItemsGrid>
        <RenderIf condition={isItemsCollapsed}>
          <ItemsGridExpander
            onClick={() => {
              setIsItemsCollapsed(false)
              setIsCollapsedTouched(true)
            }}
          >
            <img alt="Show all" src={chevronDown} />
          </ItemsGridExpander>
        </RenderIf>
      </ItemsContainer>
      <Button
        width="100%"
        buttonType="common"
        onClick={orderOrNext}
        disabled={
          selectedMenuItems.filter(
            (el) =>
              el.categoryId === product.menuItems[currentSelected].categoryId
          )?.length < product.menuItems[currentSelected].minCount
        }
      >
        {currentSelected + 1 === product.menuItems.length
          ? t('buttons.accept')
          : t('common.next')}
      </Button>
    </div>
  )
}
