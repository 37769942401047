import { FC, useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Input } from '../../../components/Input'
import { getUserData } from '../../../utils/getUserData'
import { useTranslations } from '../../../hooks/useTranslations'

import { RenderIf } from 'components/RenderIf'
import { Spinner } from 'featureSliced/entities/Spinner'
import { Button } from 'featureSliced/entities/Button'
import { useMySelector } from 'hooks/useMySelector'

import eye from 'assets/eye.svg'
import { Error, EyeImage, InputContainer, Page } from './styled'

export const Edit: FC = () => {
  // Hooks
  const t = useTranslations()

  // Store
  const { modals } = useMySelector((state) => state)

  // State
  const [isInputHidden, setIsInputHidden] = useState(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')

  // useEffects
  useEffect(() => {
    initUserData()
  }, [])

  // Functions
  const initUserData = async () => {
    try {
      setIsLoading(true)
      const { firstName, lastName, phoneNumber } = await getUserData()
      setFirstName(firstName)
      setLastName(lastName)
      setPhoneNumber(phoneNumber)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const updateUserData = async (user: any) => {
    try {
      await Auth.updateUserAttributes(user, {
        phone_number: phoneNumber,
        family_name: lastName,
        name: firstName,
      })
    } catch (e: any) {
      setError(e.message)
    }
  }

  const updatePassword = async (user: any) => {
    try {
      await Auth.changePassword(user, oldPassword, newPassword)
    } catch (e: any) {
      setError(e.message)
    }
  }

  const saveChanges = async () => {
    try {
      setIsLoading(true)
      const currentAuthUser = await Auth.currentAuthenticatedUser()
      await updateUserData(currentAuthUser)
      if (!oldPassword.length && newPassword.length) {
        setError('Insert also old password to update')
      }
      if (oldPassword.length && newPassword.length) {
        await updatePassword(currentAuthUser)
      }
    } catch (e: any) {
      setError(e.message)
    } finally {
      setIsLoading(false)
      modals
        ?.find((modal) => modal.id === 'PROFILE_MODAL')
        ?.onClose?.('PROFILE_MODAL')
    }
  }

  return (
    <>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <Page>{t('profile.editProfile')}</Page>
      <InputContainer>
        <Input
          placeholder={t('profile.firstName')}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
        <Input
          placeholder={t('profile.lastName')}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={t('auth.phoneNumber')}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        />
      </InputContainer>
      <InputContainer>
        <EyeImage
          src={eye}
          alt={isInputHidden ? t('profile.showPass') : t('profile.hidePass')}
          onClick={() => setIsInputHidden((prevValue) => !prevValue)}
        />
        <Input
          placeholder="Old password"
          type={isInputHidden ? 'password' : 'text'}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.currentTarget.value)}
        />
      </InputContainer>
      <InputContainer>
        <EyeImage
          src={eye}
          alt={isInputHidden ? t('profile.showPass') : t('profile.hidePass')}
          onClick={() => setIsInputHidden((prevValue) => !prevValue)}
        />
        <Input
          placeholder={t('profile.newPassword')}
          type={isInputHidden ? 'password' : 'text'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.currentTarget.value)}
        />
      </InputContainer>
      <Error>{error}</Error>
      <Button buttonType="common" width="100%" onClick={saveChanges}>
        {t('buttons.saveChanges')}
      </Button>
    </>
  )
}
