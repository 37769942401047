import { FC } from 'react'
import { Button } from '../../../components/Button'
import { useTranslations } from '../../../hooks/useTranslations'
import { ButtonsContainer, ConfirmationModalContainer } from './styled'
import { RenderIf } from 'components/RenderIf'

type ConfirmationModalProps = {
  text?: string
  onConfirm?: () => void
  okText?: string
  onCancel?: () => void
  cancelText?: string
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  text = '',
  onConfirm,
  onCancel,
  okText,
  cancelText,
}) => {
  const t = useTranslations()

  return (
    <ConfirmationModalContainer>
      <h2>{text}</h2>
      <ButtonsContainer>
        <RenderIf condition={!!onCancel}>
          <Button buttonType="stroke" width="100%" onClick={onCancel}>
            {cancelText || t('common.no')}
          </Button>
        </RenderIf>
        <RenderIf condition={!!onConfirm}>
          <Button buttonType="common" width="100%" onClick={onConfirm}>
            {okText || t('buttons.ok')}
          </Button>
        </RenderIf>
      </ButtonsContainer>
    </ConfirmationModalContainer>
  )
}
