import { ICartProduct } from 'store/generalTypes'

export enum TableStatuses {
  FREE = 'FREE',
  BUSY = 'BUSY',
  IN_USE = 'IN_USE',
}

export const initialState: ITable = {
  tableId: 0,
  tableStatus: TableStatuses.BUSY,
  isTableDataLoaded: false,
  isTableLoading: false,
  cloudItems: [],
  cloudTotal: 0,
  modVer: 0,
}

export interface ITable {
  tableId: number | string
  tableStatus: TableStatuses
  isTableDataLoaded: boolean
  isTableLoading: boolean
  cloudItems: ITableCloudItems[]
  cloudTotal: number
  modVer: number
}

export interface ITableCloudItems extends ICartProduct {
  categoryId: number
  count: number
  price: number
  productId: number
  menuItems: string

  kitchenFreeInfo: string
  discountP: number
  discountType: number
  guestId: number
  isManualCourse: number
  isRemoved: number
  itemGuid: string
  kitchenPrinted: number
  menuTotal: number
  originalPrice: number
  redeemPoints: number
  taxCode: number
  total: number

  uniqueId?: string //
}
