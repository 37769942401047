import { FC } from 'react'
import { Button } from 'featureSliced/entities/Button'
import { useTranslations } from 'hooks/useTranslations'
import { Products } from 'components/Products'
import { useOpenQRMProduct } from '../..'

import { ItemsContainer } from './styled'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { useMySelector } from 'hooks/useMySelector'

type AddSubTemplateProductProduct = {
  products: IProduct[]
  categoryName?: string
  closeModal: () => void
}

export const AddSubTemplateProduct: FC<AddSubTemplateProductProduct> = ({
  products,
  categoryName,
  closeModal,
}) => {
  // Hooks
  const t = useTranslations()
  const openProductModal = useOpenQRMProduct()

  // Store
  const bucketProducts = useMySelector((state) => state.bucket.products)

  // Functions
  const addProduct = (product: IProduct) => {
    openProductModal(product, categoryName)
  }

  const getAmount = (product: IProduct) => {
    return (
      bucketProducts?.find((bProduct) => bProduct?.productId === product.id)
        ?.count || 0
    )
  }

  return (
    <>
      <ItemsContainer>
        <Products
          products={products}
          onProductClick={addProduct}
          getAmount={getAmount}
        />
      </ItemsContainer>
      <Button width="100%" buttonType="common" onClick={closeModal}>
        {t('buttons.ok')}
      </Button>
    </>
  )
}
