import { FC } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { IIndivPrice } from '../../../store/reducers/SpecialsReducer/types'
import { convertToPrice } from '../../../utils/convertToPrice'
import {
  Description,
  DescriptionContainer,
  PriceContainer,
  SpecialItem,
  SpecialItemImage,
  Title,
  TitleContainer,
} from './styled'

type PriceType = {
  individualPrice: IIndivPrice
  handleProductClick: (product: IProduct) => void
}

export const Price: FC<PriceType> = ({
  individualPrice,
  handleProductClick,
}) => {
  const { productList: products } = useMySelector((state) => state.products)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const currentProduct = products.find(
    (product) => product.id === individualPrice.productId
  )

  const onProductClick = () => {
    if (currentProduct) {
      handleProductClick({
        ...currentProduct,
        betrag2: individualPrice.price2 || individualPrice.price,
      })
    }
  }

  const getPrice = (item: any) => {
    return convertToPrice(item.price2 || item.price || 0)
  }

  return (
    <RenderIf condition={!!currentProduct}>
      <SpecialItem onClick={onProductClick}>
        <SpecialItemImage src={currentProduct?.imageUrl} alt="Product" />
        <div>
          <PriceContainer>
            <p>
              {getPrice(individualPrice)} {Currency || ''}
            </p>
          </PriceContainer>
          <TitleContainer>
            <Title>{currentProduct?.title}</Title>
          </TitleContainer>
        </div>
        <DescriptionContainer>
          <Description>{individualPrice.description}</Description>
        </DescriptionContainer>
      </SpecialItem>
    </RenderIf>
  )
}
