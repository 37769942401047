export const ORANGE_COLOR = '#FF8F00'

export const lightTheme = {
  MainBackGround: '#fafafa',
  ItemsBackGround: '#ffffff',
  ItemsBorders: '#cdcdcd',
  FontColor: '#434343',
  FooterColor: '#ffffff',
}

export const darkTheme = {
  MainBackGround: '#16161c',
  ItemsBackGround: '#333337',
  ItemsBorders: '#333337',
  FontColor: '#ffffff',
  FooterColor: '#333337',
}

export const initialState: IApp = {
  isAppInit: false,
  isProductsLoaded: false,
  isPaymentAvailable: false,
  isTableTakenByMW: false,
  lastActiveCategoryId: '',
  companyData: {
    DatatransActivated: false,
    BexioID: '',
    City: '',
    CompanyID: '',
    CompanyName: '',
    ContractorFirma: '',
    ContractorName: '',
    Currency: '',
    ExistedCashRegisterErrors: '',
    Phone: '',
    PostCode: '',
    RequestOrigin: '',
    Street: '',
  },
  address: {
    id: 0,
    Bezeichnung: '',
    Country: '',
    GoogleMapURL: '',
    Mail: '',
    Phone: '',
    QRMenuClientId: 0,
    Street: '',
    Town: '',
    Website: '',
    ZipCode: '',
  },
  menu: {
    bookingVariant: 1,
    CompanyGUID: '',
    paymentVariant: null,
    selfService: 0,
    isReservationsShown: 1,
    isSocialsShown: 0,
    loginAvailable: false,
    tips: 0,
    notesAvailable: 0,
  },
  interfaceStyles: {
    appTheme: 'light',
    backgroundImage: '',
    colour: '#e32323',
    CompanyGUID: '',
    font: '',
    LanguageCode: '',
    logoImage: '',
    appIcon: '',
    productViewType: 'tile',
    AppName: '',
    MainBackGround: '#fafafa',
    ItemsBackGround: '#ffffff',
    ItemsBorders: '#cdcdcd',
    FontColor: '#434343',
    FooterColor: '#ffffff',
    abgText: '',
    agbText: '',
    color: '#000',
    dsvgoText: '',
    waiterCall: false,
  },
  clickCollectConfig: {
    leadTimeDays: 0,
    leadTimeHours: '23:59',
  },
  tableReservationConfig: {
    appTheme: 'light',
    bookingLimitPercent: 0,
    bookingPossibility: 60,
    bookingTimeIntervals: '',
    colour: '',
    daysOff: '',
    email: '',
    font: '',
    logoImage: '',
    showAllergens: false,
    showNote: false,
    showRoom: 0,
  },
}

export interface IFetchAppData {
  guid: string
  registerId: string | number
}

export interface IApp {
  isAppInit: boolean
  isProductsLoaded: boolean
  isPaymentAvailable: boolean
  isTableTakenByMW: boolean
  lastActiveCategoryId: string
  companyData: ICompanyData
  address: IAddress
  menu: IMenu
  interfaceStyles: IInterface
  clickCollectConfig: IClickConfig
  tableReservationConfig: ITableReservationConfig
}

export type IAppResponse = {
  config: {
    address: IAddress[]
    menu: IMenu[]
    interface: IInterface[]
    clickCollectConfig: IClickConfig
    tac: any
    tablereservationschedule: IReservation[]
    tablereservationinterface: IReservationSettings[]
  }
  address: IAddress
  tableReservationConfig: ITableReservationConfig
  clickCollectConfig: IClickConfig
  interfaceConfig: {
    QRMENU: {
      agbText: string
      appIcon: string
      appTheme?: 'light' | 'dark'
      backgroundImage: string
      color: string
      colour: string
      dsvgoText: string
      font: string
      logoImage: string
      productViewType: 'tile' | 'list'
      waiterCall: boolean
    }
    CLICKCOLLECT: {
      agbText: string
      appIcon: string
      appTheme?: 'light' | 'dark'
      backgroundImage: string
      color: string
      colour: string
      dsvgoText: string
      font: string
      logoImage: string
      productViewType: 'tile' | 'list'
    }
  }
  menuConfig: {
    QRMENU: {
      bookingVariant: 0 | 1 | 2 | null
      isReservationsShown: 0 | 1 | null
      isSocialsShown: 0 | 1 | null
      isUrlExtended: boolean | null
      loginAvailable: boolean | null
      notesAvailable: 0 | 1 | null
      paymentVariant: 0 | 1 | 2 | null
      selfService: 0 | 1 | null
      tips: 0 | 1 | null
    }
    CLICKCOLLECT: {
      bookingVariant: 0 | 1 | 2 | null
      isReservationsShown: 0 | 1 | null
      isSocialsShown: 0 | 1 | null
      isUrlExtended: boolean | null
      loginAvailable: boolean | null
      notesAvailable: 0 | 1 | null
      paymentVariant: 0 | 1 | 2 | null
      selfService: 0 | 1 | null
      tips: 0 | 1 | null
    }
  }
}

export interface ITableReservationConfig {
  appTheme: 'light' | 'dark'
  bookingLimitPercent: number
  bookingPossibility: number
  bookingTimeIntervals: string
  colour: string
  daysOff: string
  email: string
  font: string
  logoImage: string
  showAllergens: boolean
  showNote: boolean
  showRoom: 0 | 1
}

export interface ICompanyData {
  DatatransActivated: boolean
  BexioID: string
  City: string
  CompanyID: string
  CompanyName: string
  ContractorFirma: string
  ContractorName: string
  Currency: string
  ExistedCashRegisterErrors: string
  Phone: string
  PostCode: string
  RequestOrigin: string
  Street: string
  BranchName?: string
}

interface IAddress {
  id: number
  Bezeichnung: string
  Country: string
  GoogleMapURL: string
  Mail: string
  Phone: string
  QRMenuClientId: number
  Street: string
  Town: string
  Website: string
  ZipCode: string
}

interface IMenu {
  bookingVariant: 1 | 2 | 3
  CompanyGUID: string
  paymentVariant: 1 | 2 | null
  selfService: 0 | 1
  isReservationsShown: 0 | 1
  isSocialsShown: 0 | 1
  tips: number
  notesAvailable: 0 | 1
  loginAvailable: boolean
  isUrlExtended?: boolean
}

export interface IInterface {
  AppName: string
  appTheme: 'light' | 'dark'
  backgroundImage: string
  colour: string
  CompanyGUID: string
  font: string
  LanguageCode: string
  logoImage: string
  appIcon: string
  productViewType: 'list' | 'tile'
  MainBackGround: string
  ItemsBackGround: string
  ItemsBorders: string
  FontColor: string
  FooterColor: string
  //
  abgText: string
  agbText: string
  color: string
  dsvgoText: string
  waiterCall: boolean
}

interface IClickConfig {
  leadTimeDays: number
  leadTimeHours: string
}

interface IRoom {
  id: number
  caption: string
}

interface IReservation {
  BookingPossibility: number
  BookingTimeIntervals: string
  CompanyGUID: string
}

interface IReservationSettings {
  appTheme: string
  colour: string | null
  CompanyGUID: string
  font: string
  logoImage: string | null
  ShowAllergens: number
  ShowNote: number
  ShowRoom: number
}
