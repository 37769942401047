import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProduct } from '../CategoriesReducer/types'
import { initialState } from './types'

export const ProductsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<IProduct[]>) {
      state.productList = action.payload
    },
    setIsProductsLoaded(state) {
      state.isProductsLoaded = true
    },
  },
})

export default ProductsSlice.reducer
