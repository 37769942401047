import { FC, useEffect, useState } from 'react'
import { Card } from './Card'
import { Edit } from './Edit'
import { Orders } from './Orders'
import { Payment } from './Payment'
import { RenderIf } from 'components/RenderIf'
import { PageType } from 'featureSliced/padges/MainScreen'

type ProfileProps = {
  currentPage: PageType
  setCurrentPage: (page: PageType) => void
}

export const Profile: FC<ProfileProps> = ({ currentPage, setCurrentPage }) => {
  const [pageToShow, setPageToShow] = useState<PageType>(null)

  useEffect(() => {
    setPageToShow(currentPage)
  }, [currentPage])

  return (
    <div style={{ position: 'relative', minHeight: '100%' }}>
      <RenderIf condition={pageToShow === 'edit'}>
        <Edit />
      </RenderIf>
      <RenderIf condition={pageToShow === 'orders'}>
        <Orders />
      </RenderIf>

      <RenderIf condition={pageToShow === 'card'}>
        <Card
          moveToOrders={() => {
            setPageToShow('orders')
            setCurrentPage('orders')
          }}
        />
      </RenderIf>
      <RenderIf condition={pageToShow === 'payment'}>
        <Payment />
      </RenderIf>
    </div>
  )
}
