import { useEffect, useState } from 'react'
import Auth from '@aws-amplify/auth'

import { isAuth } from 'utils/isAuth'
import { Header } from 'components/Header'
import { FooterPaymentQRM } from './Footer'
import { RenderIf } from 'components/RenderIf'
import { errorHandler } from 'components/ErrorHandler'
import { clickCollectGET } from 'api/clickCollect/GET'
import { Spinner } from 'featureSliced/entities/Spinner'

import { Cart } from 'featureSliced/widgets/Cart'
import { Tips } from 'featureSliced/widgets/Tips'
import { SubFooter } from 'featureSliced/widgets/Cart/SubFooter'
import { AmountHandler } from 'featureSliced/widgets/AmountHandler'
import { AgeProtectionModal } from 'featureSliced/widgets/AgeProtectionModal'

import { useProductProtection } from 'hooks/useProductProtection'
import { useTranslations } from 'hooks/useTranslations'
import { useTableStatus } from 'hooks/useTableStatus'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'
import { usePayment } from 'hooks/usePayment'

import { ITableCloudItems } from 'store/reducers/QRMenuTableReducer/types'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'
import { ICartProduct } from 'store/generalTypes'

import { CardsModal } from './QRMCardsModal'

import { CurrentPageType } from './types'
import { EmptyBlock, PaymentContainer } from './styled'
import { convertToPrice } from 'utils/convertToPrice'
import { getTotalPrice } from 'featureSliced/shared/utils/getTotalPrice'
import { getProductTotalPrice } from 'utils/getProductTotalPrice'
import { qrMenuGET } from 'api/qrMenu/GET'
import { useSearchParams } from 'react-router-dom'
// import { SubFooter } from '../Cart/SubFooter'

type SplitProductType = {
  itemGuid: string
  count: number
}

export const PaymentQRM = () => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { startQRMAfterMealPayment: startPayment } = usePayment()
  const { isProductHighlyProtected, isProductProtected } =
    useProductProtection()
  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')

  // Store
  const currency = useMySelector((state) => state.app.companyData.Currency)
  const selectedQRMProducts = useMySelector((state) => state.bucket.products)
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const { cloudItems, isTableLoading, modVer } = useMySelector(
    (state) => state.qrMenuTable
  )
  console.log('sssss:', modVer)
  const isProductsLoaded = useMySelector(
    (state) => state.products.isProductsLoaded
  )

  // State
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('cart')
  const [isAVGConfirmed, setIsAVGConfirmed] = useState<boolean>(false)
  const [isUserAuth, setIsUserAuth] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tipsValue, setTipsValue] = useState<number>(0)
  const [isLoyaltyCardExisting, setIsLoyaltyCardExisting] =
    useState<boolean>(false)

  // Split state
  const [splitProducts, setSplitProducts] = useState<SplitProductType[]>([])
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<string>()

  // useEffect
  useEffect(() => {
    checkIfLoyaltyCardExists()
  }, [])

  useEffect(() => {
    // checkTableStatus()
  }, [])

  useEffect(() => {
    checkIsAuth()
  }, [paymentVariant])

  // Functions
  const handleSplitProductClick = (
    _productId: number,
    _e: any,
    product: ICartProduct
  ) => {
    setSplitProducts((prevValue) => {
      if (!prevValue?.find((el) => el.itemGuid === product.itemGuid)) {
        setCurrentSelectedProduct(product.itemGuid)
        return [
          ...prevValue,
          { itemGuid: product.itemGuid || '', count: product.count },
        ]
      } else {
        setCurrentSelectedProduct(undefined)
        return prevValue?.filter((el) => el.itemGuid !== product.itemGuid)
      }
    })
  }

  const getIsProductSelectedForSplit = (product: ITableCloudItems) =>
    !!splitProducts?.find((el) => el.itemGuid === product.itemGuid)

  const getResultPrice = (): number => {
    if (paymentVariant === 1) {
      return getTotalPrice(selectedQRMProducts)
      // return `${convertToPrice(
      //   getTotalPrice(selectedQRMProducts) + tipsValue
      // )} ${currency || ''}`
    }
    if (paymentVariant === 2) {
      if (currentPage === 'split' || splitProducts?.length) {
        // if (splitProducts?.length) {
        // if (currentPage === 'split') {
        const total = splitProducts?.reduce((acc, curr) => {
          const cloudItem = cloudItems?.find(
            (cItem) => cItem.itemGuid === curr.itemGuid
          )
          if (cloudItem) {
            const productTotal = getProductTotalPrice(cloudItem)
            return (acc += productTotal)
          }
          return acc
        }, 0)
        return total
        // return `${convertToPrice(total + tipsValue)} ${currency || ''}`
      } else {
        const total = cloudItems?.reduce((acc, curr) => {
          const productTotal = getProductTotalPrice(curr)
          return (acc += productTotal)
        }, 0)
        return total
        // return `${convertToPrice(total + tipsValue)} ${currency || ''}`
      }
    } else {
      return 0
    }
  }

  const getResultPriceWithCurrency = () => {
    const total = getResultPrice()
    return `${convertToPrice(total + tipsValue)} ${currency || ''}`
  }

  const getProductSplitCount = (product: ITableCloudItems) =>
    splitProducts?.find((el) => el.itemGuid === product.itemGuid)?.count || 0

  const handleSplitCount = (newValue: number) => {
    if (currentSelectedProduct) {
      setSplitProducts((prevValue) => {
        if (newValue) {
          return prevValue?.map((el) => {
            if (el.itemGuid === currentSelectedProduct) {
              return { ...el, count: newValue }
            } else {
              return el
            }
          })
        } else {
          return prevValue?.filter(
            (el) => el.itemGuid !== currentSelectedProduct
          )
        }
      })
    }
  }

  const checkIsAuth = async () => {
    const res = await isAuth()
    setIsUserAuth(res)
  }

  const getIsNextAndPayDisabled = () => {
    return (
      (!isUserAuth && !isAVGConfirmed) ||
      (currentPage === 'split' && !splitProducts?.length)
    )
    // return currentPage === 'split' && !splitProducts?.length
  }

  const checkIfLoyaltyCardExists = async () => {
    try {
      const res = await clickCollectGET.customerCard()
      setIsLoyaltyCardExisting(!!res?.cardNumber)
    } catch (e) {
      errorHandler(e)
    }
  }

  const openAgeProtectionModal = (
    itemsToPay: ITableCloudItems[] | IBucketProduct[],
    isHighlyProtected = false
  ) => {
    openModal({
      id: 'AGE_PROTECTION_MODAL',
      title: t('common.confirmYourAge'),
      components: [
        ({ closeThisModal }) => (
          <AgeProtectionModal
            isHighlyProtected={isHighlyProtected}
            startPayment={() => {
              startPaymentRequest(itemsToPay)
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const startPaymentProccess = () => {
    const itemsToPay: ITableCloudItems[] | IBucketProduct[] = (function () {
      switch (true) {
        case Boolean(splitProducts?.length):
          return splitProducts?.map((splitP) => {
            const fullItem = cloudItems?.find(
              (cloudP) => cloudP.itemGuid === splitP.itemGuid
            )
            if (fullItem) {
              return {
                ...fullItem,
                count: splitP.count,
              }
            }
            return splitP as ITableCloudItems
          })
        case paymentVariant === 1:
          return selectedQRMProducts
        case paymentVariant === 2:
          return cloudItems
        default:
          return selectedQRMProducts || cloudItems
      }
    })()
    if (
      itemsToPay?.some((product) => isProductHighlyProtected(product.productId))
    ) {
      openAgeProtectionModal(itemsToPay, true)
    } else if (
      itemsToPay?.some((product) => isProductProtected(product.productId))
    ) {
      openAgeProtectionModal(itemsToPay)
    } else {
      startPaymentRequest(itemsToPay)
    }
  }

  const startPaymentRequest = async (
    itemsToPay: ITableCloudItems[] | IBucketProduct[]
  ) => {
    if (isAVGConfirmed || isUserAuth) {
      try {
        setIsLoading(true)

        /// TODO: check modVer with new endpoint
        // const paymentData = await qrMenuGET.cloudOrders(tableId)
        // const modVerCurrent = paymentData?.storageItem?.tableOrder?.modVer
        // if (modVerCurrent !== modVer) {
        //   window.location.reload()
        //   return
        // }
        ///

        if (!isUserAuth) {
          await startPayment(itemsToPay, tipsValue)
        }

        if (isUserAuth) {
          const currentUserInfo = await Auth.currentUserInfo()
          const attributes = currentUserInfo?.attributes
          if (
            (!attributes || !attributes?.['custom:active_card_token']) &&
            !isLoyaltyCardExisting
          ) {
            await startPayment(itemsToPay, tipsValue)
          } else {
            setIsLoading(false)
            openModal({
              id: 'PAYMENT_CARDS_MODAL',
              title: 'Choose payment variant',
              components: [
                ({ closeThisModal }) => (
                  <CardsModal
                    isLoyaltyCardExisting={isLoyaltyCardExisting}
                    itemsToPay={itemsToPay}
                    tipsValue={tipsValue}
                    userCardData={attributes?.['custom:active_card_token']}
                    closeModal={closeThisModal}
                  />
                ),
              ],
            })
          }
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  // Components
  const renderAmountHandler = () => (
    <AmountHandler
      isSmall
      currentValue={
        splitProducts?.find((el) => el.itemGuid === currentSelectedProduct)
          ?.count || 0
      }
      minValue={0}
      maxValue={
        cloudItems?.find((el) => el.itemGuid === currentSelectedProduct)?.count
      }
      onDecrease={handleSplitCount}
      onIncrease={handleSplitCount}
    />
  )

  const getProductsToShow = (isProductsLoadedParam: boolean) => {
    switch (true) {
      case !isProductsLoadedParam:
        return []
      case isProductsLoadedParam && paymentVariant === 1:
        return selectedQRMProducts
      case isProductsLoadedParam && paymentVariant === 2:
        return cloudItems
      default:
        return []
    }
  }

  return (
    <>
      <PaymentContainer>
        <Header />
        <EmptyBlock />
        <RenderIf condition={isTableLoading || !isProductsLoaded}>
          <Spinner type="transparent" />
        </RenderIf>

        {/* CART */}
        <RenderIf condition={currentPage === 'cart'}>
          <Cart products={getProductsToShow(isProductsLoaded)} />
        </RenderIf>

        {/* SPLIT */}
        {/* Split is unavailable for Pay before meal */}
        <RenderIf condition={currentPage === 'split' && paymentVariant === 2}>
          <Cart
            isSplit
            products={cloudItems}
            handleProductClick={handleSplitProductClick}
            getIsProductSelected={getIsProductSelectedForSplit}
          />
        </RenderIf>

        {/* TIPS */}
        <RenderIf condition={currentPage === 'tips'}>
          <Tips
            // total={cloudTotal}
            total={getResultPrice()}
            tipsValue={tipsValue}
            onChange={setTipsValue}
          />
        </RenderIf>
        {/* SUBFOOTER */}
        <SubFooter
          isAVGConfirmed={isAVGConfirmed}
          setIsAVGConfirmed={setIsAVGConfirmed}
          //
          isUserAuth={isUserAuth}
          setIsUserAuth={setIsUserAuth}
        />
        <EmptyBlock height="120px" />
      </PaymentContainer>

      {/* FOOTER */}
      <FooterPaymentQRM
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        renderAmountHandler={renderAmountHandler}
        getIsNextAndPayDisabled={getIsNextAndPayDisabled}
        tipsValue={tipsValue}
        startPaymentProccess={startPaymentProccess}
        isLoading={isLoading}
        getResultPrice={getResultPriceWithCurrency}
        resetSplitProducts={() => setSplitProducts([])}
      />
    </>
  )
}
