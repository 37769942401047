import { FC } from 'react'
import { IKitchenInfo } from 'store/reducers/CategoriesReducer/types'

import { KitchenInfoTitle, KitchenItem, KitchenItemsContainer } from './styled'
import { KitchenInfoItemType } from '../types'

type KitchenInfoProps = {
  kitchenInfo: IKitchenInfo[] | string | null
  kitchenInfoState: [
    KitchenInfoItemType,
    (value: (value: KitchenInfoItemType) => any) => void
  ]
}

export const KitchenInfo: FC<KitchenInfoProps> = ({
  kitchenInfo,
  kitchenInfoState,
}) => {
  // Functions
  const handleKitchenInfo = (infoToAdd: KitchenInfoItemType) => {
    const [keyToChange, valueToSet] = Object.entries(infoToAdd)[0]
    kitchenInfoState?.[1]?.((prevInfo: KitchenInfoItemType) => {
      if (prevInfo[keyToChange]) {
        const prevValueList = prevInfo[keyToChange].split(',')
        const prevIndex = prevValueList.indexOf(valueToSet)

        if (prevIndex !== -1) {
          prevValueList.splice(prevIndex, 1)
        } else {
          prevValueList.push(valueToSet)
        }
        prevInfo[keyToChange] = prevValueList.join(',')
      } else {
        prevInfo[keyToChange] = valueToSet
      }

      if (prevInfo[keyToChange] === '') {
        delete prevInfo[keyToChange]
      }

      return { ...prevInfo }
    })
  }

  return (
    <>
      {Array.isArray(kitchenInfo) &&
        kitchenInfo?.map((infoItem) => (
          <div key={`kitchenInfo_${infoItem.title}`}>
            <KitchenInfoTitle>{infoItem.title}</KitchenInfoTitle>
            <div>
              <KitchenItemsContainer>
                {infoItem.values?.map((value) => (
                  <KitchenItem
                    key={value}
                    isActive={
                      kitchenInfoState[0]?.[infoItem.title]?.indexOf(value) > -1
                    }
                    onClick={() =>
                      handleKitchenInfo({ [infoItem.title]: value })
                    }
                  >
                    {value}
                  </KitchenItem>
                ))}
              </KitchenItemsContainer>
            </div>
          </div>
        ))}
    </>
  )
}
