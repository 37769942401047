import { RenderIf } from 'components/RenderIf'
import { PaymentCC } from 'featureSliced/widgets/PaymentCC'
import { PaymentQRM } from 'featureSliced/widgets/PaymentQRM'

export const Payment = () => {
  return (
    <>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <PaymentQRM />
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <PaymentCC />
      </RenderIf>
    </>
  )
}
