import { FC, useEffect, useState } from 'react'

import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { isProductProtected } from 'utils/isProductProtected'
import { getProtectedAge } from 'utils/getProtectedAge'
import { RenderIf } from 'components/RenderIf'

import { useOpenNote } from 'hooks/useOpenNote'
import { useMySelector } from 'hooks/useMySelector'
import { useWishlist } from 'hooks/useWishlist'

import { IconFilled } from 'components/IconsContainers/styled'
import { ReactComponent as ClocheIcon } from 'assets/cloches/clocheSquare.svg'
import heartIcon from 'assets/hearts/empty_l.svg'
import noteIcon from 'assets/notes/large_note.svg'

import {
  AgeProtection,
  ProductMainImage,
  ProductMainImageContainer,
  SecondaryImage,
} from './styled'

type ProductImageProps = {
  product: IProduct
}

export const ProductImage: FC<ProductImageProps> = ({ product }) => {
  // Hooks
  const openNote = useOpenNote()
  const handleWishlist = useWishlist()

  // Store
  const color = useMySelector((state) => state.app.interfaceStyles.colour)
  const notesAvailable = useMySelector((state) => state.app.menu.notesAvailable)

  // State
  const [isImgErrored, setIsImageErrored] = useState(false)
  const [isWishlisted, setIsWishlisted] = useState<boolean>(false)

  //useEffects
  useEffect(() => {
    const isSelected = !!handleLocalStorage('wishlist')?.find(
      (listItem: number) => listItem === product.id
    )
    setIsWishlisted(isSelected)
  }, [product.id])

  // Functions
  const handleOpenNote = (productId: number) => {
    if (notesAvailable === 1) {
      openNote(productId)
    }
  }

  const handleWishListClick = (productId: number) => {
    handleWishlist(productId)
    setIsWishlisted((prevValue) => !prevValue)
  }

  return (
    <ProductMainImageContainer>
      <RenderIf condition={!isImgErrored}>
        <ProductMainImage
          src={product.imageUrl || 'ERRORED_IMAGE'}
          alt="product"
          onError={({ currentTarget }) => {
            setIsImageErrored(true)
            currentTarget.onerror = null // prevents looping
          }}
        />
      </RenderIf>
      <RenderIf condition={isImgErrored}>
        <IconFilled color={`${color}80`}>
          <ClocheIcon />
        </IconFilled>
      </RenderIf>
      <RenderIf condition={isProductProtected(product)}>
        <AgeProtection>{getProtectedAge(product)}</AgeProtection>
      </RenderIf>
      <RenderIf
        condition={notesAvailable === 1 && location.pathname !== '/menu'}
      >
        <SecondaryImage onClick={() => handleOpenNote(product.id)}>
          <img alt="note" src={noteIcon} />
        </SecondaryImage>
      </RenderIf>
      <RenderIf condition={location.pathname === '/menu'}>
        <SecondaryImage
          onClick={() => handleWishListClick(product.id)}
          isActive={isWishlisted}
        >
          <img alt="wishlist" src={heartIcon} />
        </SecondaryImage>
      </RenderIf>
    </ProductMainImageContainer>
  )
}
