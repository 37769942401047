import { RenderIf } from 'components/RenderIf'
import { CCConfirmation } from './CCConfirmaion'
import { QRMConfirmation } from './QRMConfirmation'
//
import { useCompleteDataTrans } from 'hooks/useCompleteDataTrans_OLD'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenNoReceiptModal } from 'hooks/useOpenNoReceiptModal'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const PaymentConfirmation = () => {
  //
  // const [searchParams] = useSearchParams()
  // const completeDataTrans = useCompleteDataTrans()
  // const isAppInit = useMySelector((state) => state.app.isAppInit)
  // const openNoReceiptModal = useOpenNoReceiptModal()

  // // State
  // const payment_success_nr = searchParams.get('payment_success_nr')
  // const datatransTrxId = searchParams.get('datatransTrxId')
  // const saferPayOrderGuid = searchParams.get('orderGuid')

  // useEffect(() => {
  //   if ((datatransTrxId || saferPayOrderGuid) && isAppInit) {
  //     completeDataTrans()
  //   }
  // }, [isAppInit, datatransTrxId, saferPayOrderGuid])

  // useEffect(() => {
  //   if (payment_success_nr) {
  //     openNoReceiptModal(payment_success_nr)
  //   }
  // }, [payment_success_nr])
  //
  return (
    <>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <QRMConfirmation />
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <CCConfirmation />
      </RenderIf>
    </>
  )
}
