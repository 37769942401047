import { FC } from 'react'
import { RenderIf } from 'components/RenderIf'
import { AllergenImages } from 'utils/AllergenImages'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'

import {
  Allergen,
  AllergenItem,
  AllergensContainer,
  AllergensSections,
  AllergensTitle,
  AllergenText,
} from './styled'

type AllergensProps = {
  product: IProduct
}

export const Allergens: FC<AllergensProps> = ({ product }) => {
  // Hooks
  const t = useTranslations()

  // Store
  const { allergens } = useMySelector((state) => state.common)

  return (
    <RenderIf condition={!!product?.allergens?.length}>
      <AllergensSections>
        <AllergensTitle>{t('filters.allergens')}</AllergensTitle>
        <AllergensContainer>
          {product?.allergens?.split(', ')?.map((id, idx) => {
            return (
              <AllergenItem key={`product_allergen__${id}`}>
                <Allergen
                  src={AllergenImages[id] || AllergenImages[0]}
                  alt={id}
                />
                <AllergenText>
                  {product.linkRA
                    ? product.allergenLabels?.split(',')?.[idx]
                    : allergens.find((allergen) => allergen.id === +id)?.name}
                </AllergenText>
              </AllergenItem>
            )
          })}
        </AllergensContainer>
      </AllergensSections>
    </RenderIf>
  )
}
