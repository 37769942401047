import { RenderIf } from 'components/RenderIf'
import { AuthModal } from 'featureSliced/features/AuthModal'
import { TermsAndConditionsCheckbox } from 'featureSliced/features/TermsConditions/checkBox'
import { useMySelector } from 'hooks/useMySelector'
import { useOpenModal } from 'hooks/useOpenModal'
import { useTranslations } from 'hooks/useTranslations'
import { FC, useEffect, useState } from 'react'
import { isAuth } from 'utils/isAuth'
import {
  SignUpButton,
  SignUpDecorated,
  SignUpDescription,
  SubFooterCheckboxContainer,
  SubFooterComponent,
} from './styled'

type SubFooterProps = {
  isAVGConfirmed: boolean
  setIsAVGConfirmed: (value: boolean) => void
  //
  isUserAuth: boolean
  setIsUserAuth: (value: boolean) => void
}

export const SubFooter: FC<SubFooterProps> = ({
  isAVGConfirmed,
  setIsAVGConfirmed,
  //
  isUserAuth,
  setIsUserAuth,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  // Store
  const isLoginAvailable = useMySelector(
    (state) => state.app.menu.loginAvailable
  )

  // useEffect
  useEffect(() => {
    // if (location.pathname === '/payment') {
    checkIsAuth()
    // }
  }, [])

  // Functions
  const checkIsAuth = async () => {
    const res = await isAuth()
    setIsUserAuth(res)
  }

  const openSignupModal = () => {
    openModal({
      id: 'AUTH_MODAL',
      title: '',
      components: [
        () => <AuthModal onLogin={checkIsAuth} initialPage="signup" />,
      ],
    })
  }

  return (
    <RenderIf condition={!isUserAuth}>
      <SubFooterCheckboxContainer>
        <TermsAndConditionsCheckbox
          isChecked={isAVGConfirmed}
          handleCheck={setIsAVGConfirmed}
        />
      </SubFooterCheckboxContainer>
      <RenderIf condition={isLoginAvailable}>
        <SubFooterComponent onClick={openSignupModal}>
          <SignUpButton>
            <span>{t('scanner.orJust')}</span>
            <SignUpDecorated>
              {t('auth.signUp')?.toLowerCase()}*
            </SignUpDecorated>
          </SignUpButton>
          <SignUpDescription>{t('auth.signUpAdvantages')}</SignUpDescription>
        </SubFooterComponent>
      </RenderIf>
    </RenderIf>
  )
}
