import { FC, MouseEvent } from 'react'
import { useMySelector } from '../../../hooks/useMySelector'
import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { roundValueTo } from '../../../utils/roundValueTo'
import { ProductGrided } from '../ProductGrided/ProductGrided'
import { ProductListed } from '../ProductListed/ProductListed'
import { ProductsGrid, ProductsList } from '../styled'

type ProductsProps = {
  products: IProduct[]
  onProductClick: (product: IProduct, e: MouseEvent<HTMLElement>) => void
}

export const MenuProducts: FC<ProductsProps> = ({
  products,
  onProductClick,
}) => {
  // Store
  const selectedProducts = useMySelector((state) => state.bucket.products)
  const { productViewType } = useMySelector(
    (state) => state.app.interfaceStyles
  )

  const ContainerComponent =
    productViewType === 'list' ? ProductsList : ProductsGrid
  const ProductsComponent =
    productViewType === 'list' ? ProductListed : ProductGrided

  // Functions
  const getAmount = (product: IProduct) => {
    return selectedProducts?.reduce((acc, curr) => {
      if (curr.productId === product.id) {
        return roundValueTo(acc + curr.count)
      } else {
        return acc
      }
    }, 0)
  }

  return (
    <ContainerComponent>
      {products
        ?.slice()
        ?.sort(
          (a, b) => Number(a.manualSortIdx || 0) - Number(b.manualSortIdx || 0)
        )
        ?.map((product, i) => {
          return (
            <ProductsComponent
              withLazy
              key={`${product?.id}_${i}`}
              product={product}
              amount={getAmount(product)}
              onProductClick={onProductClick}
              showPricePerItem={true}
            />
          )
        })}
    </ContainerComponent>
  )
}
