import styled from 'styled-components'

export const FooterContainer = styled.div`
  position: fixed;
  top: calc(100% - 120px);
  left: 0;
  right: 0;
  padding: 10px 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 50px;
  grid-gap: 10px;
  background-color: ${({ theme }) => theme.FooterColor};
  color: ${({ theme }) => theme.FontColor};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-top: ${({ theme }) => `1px solid ${theme.ItemBorder}`};
  height: 120px;
  width: 100%;

  & button {
    height: 50px !important;
  }
`

export const Total = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    line-height: 30px;
    font-size: 17px;
    font-weight: 500;
  }
  span:last-child {
    font-weight: 600;
    margin-left: 15px;
  }
`
