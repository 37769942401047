import { FC, useEffect, useState } from 'react'
import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { CommonProductComponent } from '../ComonProduct'
import { AddCommonProductFooter } from '../footers/AddCommonProductFooter'
import { KitchenInfoItemType } from '../types'
import { useMySelector } from 'hooks/useMySelector'

type AddCommonProductProps = {
  product: IProduct
  closeProduct: () => void
}

export const AddCommonProduct: FC<AddCommonProductProps> = ({
  product,
  closeProduct,
}) => {
  // Store
  const bucketProducts = useMySelector((state) => state.bucket.products)

  // State
  const [currentAmount, setCurrentAmount] = useState<number>(1)
  const kitchenInfoState = useState<KitchenInfoItemType>({})

  // useEffect
  useEffect(() => {
    if (bucketProducts?.length) {
      const addedProduct = bucketProducts?.find((bProduct) => {
        return bProduct?.productId === product.id
      })
      if (addedProduct) {
        kitchenInfoState[1](JSON.parse(addedProduct?.kitchenInfo))
        setCurrentAmount(addedProduct?.count)
      }
    }
  }, [bucketProducts, product])

  return (
    <>
      <CommonProductComponent
        product={product}
        kitchenInfoState={kitchenInfoState}
        currentAmount={currentAmount}
      />
      <AddCommonProductFooter
        product={product}
        selectedKitchenInfo={kitchenInfoState[0]}
        amountState={[currentAmount, setCurrentAmount]}
        closeProduct={closeProduct}
        initAmount={currentAmount}
      />
    </>
  )
}
