import { useMySelector } from './useMySelector'
import {
  isProductProtected as isProductProtectedInner,
  isProductHighlyProtected as isProductHighlyProtectedInner,
} from 'utils/isProductProtected'

export const useProductProtection = () => {
  const productList = useMySelector((state) => state.products.productList)

  const isProductProtected = (productId: number) => {
    return isProductProtectedInner(
      productList?.find((product) => product.id === productId)
    )
  }

  const isProductHighlyProtected = (productId: number) => {
    return isProductHighlyProtectedInner(
      productList?.find((product) => product.id === productId)
    )
  }

  return {
    isProductProtected,
    isProductHighlyProtected,
  }
}
