import { FC, MouseEvent } from 'react'
import { RenderIf } from 'components/RenderIf'
import { useTranslations } from 'hooks/useTranslations'
import { ICartProduct } from 'store/generalTypes'
import { CartProduct } from './CartProduct'
import { SubFooter } from './SubFooter'

import { ITableCloudItems } from 'store/reducers/QRMenuTableReducer/types'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'
import { PaymentProductsContainer, TableDiv } from './styled'

type CartProps = {
  products?: ICartProduct[]
  handleProductClick?: (
    productId: number,
    e: MouseEvent<HTMLElement>,
    product: ICartProduct
  ) => void
  getIsProductSelected?: (product: ITableCloudItems) => boolean
  isSplit?: boolean
  children?: any
}

export type IPosition = {
  x: number
  y: number
}

export const Cart: FC<CartProps> = ({
  products,
  handleProductClick,
  getIsProductSelected,
  isSplit,
  children,
}) => {
  // Hooks
  const t = useTranslations()

  return (
    <>
      <TableDiv>{isSplit ? t('order.split') : t('order.yourOrder')}</TableDiv>
      <PaymentProductsContainer>
        <RenderIf condition={!!products?.length && !children}>
          {products?.map(
            (elP: ITableCloudItems | ICartProduct | IBucketProduct, i) => (
              <CartProduct
                key={`${elP.uniqueId || elP.itemGuid || elP.productId}_${i}`}
                product={elP}
                isSelected={getIsProductSelected?.(elP as ITableCloudItems)}
                handleProductClick={handleProductClick}
              />
            )
          )}
        </RenderIf>
        <RenderIf condition={!!products?.length && children}>
          {children}
        </RenderIf>
      </PaymentProductsContainer>
    </>
  )
}
