import { FC, useEffect } from 'react'
import { RenderIf } from 'components/RenderIf'
import { getPrice } from 'utils/getProductPrice'
import { Button } from 'featureSliced/entities/Button'
import { useMySelector } from 'hooks/useMySelector'
import { useTranslations } from 'hooks/useTranslations'
import { useMyDispatch } from 'hooks/useMyDispatch'
import { AmountHandler } from 'featureSliced/widgets/AmountHandler'

import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { QRMBucketSlice } from 'store/reducers/QRMenuBucketReducer'
import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'

import { Divider, FooterContainer } from '../ComonProduct/styled'
import { KitchenInfoItemType } from '../types'

type AddCommonProductFooterProps = {
  product: IProduct
  amountState: [number, (value: number) => void]
  closeProduct: () => void
  selectedKitchenInfo: KitchenInfoItemType
  initAmount: number
}

export const AddCommonProductFooter: FC<AddCommonProductFooterProps> = ({
  product,
  amountState,
  closeProduct,
  selectedKitchenInfo,
  initAmount,
}) => {
  // Hooks
  const dispatch = useMyDispatch()
  const addProductToBucket = QRMBucketSlice.actions.addProductToBucket
  const t = useTranslations()
  // Store
  const tableStatus = useMySelector((state) => state.qrMenuTable.tableStatus)
  const bookingVariant = useMySelector((state) => state.app.menu.bookingVariant)

  // State
  const [currentAmount, setCurrentAmount] = amountState

  // useEffect
  useEffect(() => {
    if (initAmount) {
      setCurrentAmount(initAmount)
    }
  }, [initAmount])

  // Functions
  const getMultiplier = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 2) {
      return 100
    } else {
      return 1
    }
  }

  const getStep = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 3) {
      return 0.1
    } else {
      return 1
    }
  }

  const addToBucket = () => {
    const { id, categoryId, unitSellCode } = product
    const productToAdd = {
      uniqueId: `${id}_${JSON.stringify(selectedKitchenInfo)}`,
      productId: id,
      categoryId,
      count: currentAmount,
      kitchenInfo: JSON.stringify(selectedKitchenInfo || {}),
      price: getPrice(product),
      addedAsWeight: unitSellCode === 2 || unitSellCode === 3,
    }
    dispatch(addProductToBucket(productToAdd))
    closeProduct()
  }

  return (
    <RenderIf
      key={`${navigator.onLine}${bookingVariant}${tableStatus}${product.soldOut}`}
      condition={
        navigator.onLine &&
        bookingVariant === 2 &&
        tableStatus !== TableStatuses.BUSY &&
        !product.soldOut
      }
    >
      <Divider />
      <FooterContainer>
        <AmountHandler
          multiplier={getMultiplier(product.unitSellCode)}
          step={getStep(product.unitSellCode)}
          currentValue={currentAmount}
          onDecrease={setCurrentAmount}
          onIncrease={setCurrentAmount}
          // minValue={product.ClickCollectOrderMin} // only needed for C&C
          // maxValue={product.ClickCollectOrderMax} // only needed for C&C
        />
        <Button
          buttonType="common"
          disabled={tableStatus === TableStatuses.BUSY}
          width="100%"
          onClick={addToBucket}
        >
          {/* TODO: translations */}
          {currentAmount ? t('products.addOrder') : 'Remove'}
        </Button>
      </FooterContainer>
    </RenderIf>
  )
}
