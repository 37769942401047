import { QRMTableSlice } from '.'
import { AppDispatch, store } from '../../index'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { TableStatuses } from './types'

export const loadTableData =
  (tableId: string | number | null) => async (dispatch: AppDispatch) => {
    const paymentVariant = store.getState().app.menu.paymentVariant
    if (paymentVariant === 2) {
      try {
        // const isTableLoading = store.getState().qrMenuTable.isTableLoading
        // if (tableId && !isTableLoading) {
        if (tableId) {
          dispatch(QRMTableSlice.actions.setIsTableLoading(true))
          const paymentData = await qrMenuGET.cloudOrders(tableId)
          const items = paymentData?.storageItem?.tableOrder?.items?.filter(
            (el) => !el.isRemoved
          )
          const tableIdRes = paymentData?.storageItem?.tableOrder?.tableId
          const modVer = paymentData?.storageItem?.tableOrder?.modVer

          const tableStatus = items?.length
            ? TableStatuses.IN_USE
            : TableStatuses.FREE
          dispatch(QRMTableSlice.actions.setTableStatus(tableStatus))
          dispatch(QRMTableSlice.actions.setCloudItems(items || []))
          dispatch(QRMTableSlice.actions.setModVer(modVer || 0))
          dispatch(QRMTableSlice.actions.setTableId(tableId || tableIdRes || 0))
          dispatch(
            QRMTableSlice.actions.setCloudTotal(
              paymentData?.storageItem?.tableOrder?.total || 0
            )
          )
          return tableStatus
        } else {
          dispatch(QRMTableSlice.actions.setTableStatus(TableStatuses.BUSY))
          return TableStatuses.BUSY
        }
      } catch (e) {
        dispatch(QRMTableSlice.actions.setTableStatus(TableStatuses.BUSY))
        return TableStatuses.BUSY
      } finally {
        dispatch(QRMTableSlice.actions.setIsTableDataLoaded())
      }
    }
    if (paymentVariant === 1) {
      dispatch(QRMTableSlice.actions.setTableStatus(TableStatuses.FREE))
      dispatch(QRMTableSlice.actions.setIsTableDataLoaded())
      return TableStatuses.FREE
    }
    return TableStatuses.BUSY
  }
