import { IProduct } from '../store/reducers/CategoriesReducer/types'

export const getProtectedAge = (product?: IProduct) => {
  if (product?.youthProtectionValue === '1') {
    return '16+'
  }
  if (product?.youthProtectionValue === '2') {
    return '18+'
  }
  return ''
}
