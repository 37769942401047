// TODO: simplify
import { FC, useEffect, useState } from 'react'
import { clickCollectPOST } from '../../../api/clickCollect/POST'
import { DataTrans } from '../../../components/Checkout/DataTrans'
import { Input } from '../../../components/Input'
import { RadioContainer, RefillCard, ResultText } from './styled'
import { Radio } from '../../../components/Radio/styled'
import { isAuth } from '../../../utils/isAuth'
import Auth from '@aws-amplify/auth'
import { CCProvider } from '../../../utils/getCCProviderIcon'
import { errorHandler } from '../../../components/ErrorHandler'
import { useSearchParams } from 'react-router-dom'
import { convertToPrice } from '../../../utils/convertToPrice'
import { openLink } from '../../../utils/openLink'
import { clickCollectGET } from '../../../api/clickCollect/GET'
import { useTranslations } from 'hooks/useTranslations'
import { useMySelector } from 'hooks/useMySelector'
import { RenderIf } from 'components/RenderIf'
import { Spinner } from 'featureSliced/entities/Spinner'
import { CheckBox } from 'featureSliced/entities/CheckBox'
import { Button } from 'featureSliced/entities/Button'

type FeeSetting = {
  from: number
  to: number
  percentage: number
}

type RefillCardModal = {
  isWallet?: boolean
  feeDetailsProps?: FeeSetting[] | null
  onClose: () => void
}

export const RefillCardModal: FC<RefillCardModal> = ({
  isWallet,
  feeDetailsProps,
  onClose,
}) => {
  // Hooks
  const t = useTranslations()
  const [searchParams, setSearchParams] = useSearchParams()
  const { DatatransActivated, Currency } = useMySelector((state) => {
    return state.app.companyData
  })
  const walletCardId = searchParams.get('walletCardId')

  // State
  const [refillValue, setRefillValue] = useState('')
  const [tipsValue, setTipsValue] = useState(0)
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // saved card State
  const [userCardData, setUserCardData] = useState<any>()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [useSavedCard, setUseSavedCard] = useState<boolean>(false)
  const [updateActiveCard, setUpdateActiveCard] = useState(false)
  const [feeDetails, setFeeDetails] = useState<FeeSetting[]>()
  const [isFeeDetailsLoading, setIsFeeDetailsLoading] = useState<boolean>(false)

  //useEffect
  useEffect(() => {
    initUserData()
  }, [])

  useEffect(() => {
    getFeeDetails()
  }, [feeDetailsProps])

  // clickCollectGET.cardDetails

  useEffect(() => {
    const initFee = feeDetails?.filter((el) => el.percentage)?.[0]
    setRefillValue(convertToPrice(initFee?.from?.toString() || ''))
    setTipsValue(initFee?.percentage || 0)
  }, [feeDetails])

  // Functions

  const getFeeDetails = async () => {
    if (feeDetailsProps) {
      setFeeDetails(feeDetailsProps)
    } else {
      try {
        setIsFeeDetailsLoading(true)
        const details = await clickCollectGET.cardDetails()
        setFeeDetails(details?.settings)
      } catch (e) {
        console.log('Error:', e)
      } finally {
        setIsFeeDetailsLoading(false)
      }
    }
  }

  const initUserData = async () => {
    const isUserAuth = await isAuth()

    if (isUserAuth) {
      const currentUserInfo = await Auth.currentUserInfo()
      const attributes = currentUserInfo?.attributes
      if (attributes?.['custom:active_card_token']) {
        setUserCardData(JSON.parse(attributes?.['custom:active_card_token']))
        setUseSavedCard(true)
      }
    }

    setIsAuthorized(!!isUserAuth)
    setIsLoading(false)
  }

  const handleInputChange = (e: any) => {
    const value = Number(e.currentTarget.value)
    if (!isNaN(value)) {
      setRefillValue(e.target.value?.replace(/\.(\d{1,2}).*$/, '.$1'))
      const fee = feeDetails?.find((el) => {
        const isGreaterThanFrom = el.from <= value || !el.from
        const isLowerThanTo = el.to >= value || !el.to
        return isGreaterThanFrom && isLowerThanTo
      })
      setTipsValue(fee?.percentage || 0)
    }
  }

  const refill = async () => {
    if (refillValue && DatatransActivated) {
      try {
        setIsLoading(true)
        let result
        if (isWallet) {
          result = await clickCollectPOST.refillCardRequestWallet(
            +refillValue,
            walletCardId || ''
          )
        } else {
          result = await clickCollectPOST.refillCardRequest(
            +refillValue,
            !useSavedCard,
            updateActiveCard
          )
        }
        if (result.PaymentProvider === 1) {
          if (result.PaymentType === 'default') {
            setPaymentId(result?.TransactionId)
          }
          if (result.PaymentType === 'savedCard') {
            completeCardRefill(result?.TransactionId, null)
          }
        }
        if (result.PaymentProvider === 2) {
          if (result.PaymentType === 'default' && result?.RedirectUrl) {
            openLink(result.RedirectUrl)
          }
          if (result.PaymentType === 'savedCard') {
            completeCardRefill(null, result?.TransactionId)
          }
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        // setIsLoading(true)
      }
    }
  }

  const completeCardRefill = async (
    datatransTrxId: string | null,
    orderGuid: string | null
  ) => {
    setIsLoading(true)
    const success = await clickCollectPOST.refillCardComplete(
      datatransTrxId,
      orderGuid
    )
    if (success) onClose()
    searchParams.delete('show_card')
    searchParams.delete('orderGuid')
    searchParams.delete('datatransTrxId')
    setSearchParams(searchParams)
  }

  const addPercentage = (value: number, percentage: number) => {
    return convertToPrice(
      (value + value * (percentage / 100))
        ?.toString()
        ?.replace(/\.(\d{1,2}).*$/, '.$1')
    )
  }

  return (
    <RefillCard>
      <RenderIf condition={!!paymentId}>
        <DataTrans transactionId={paymentId as string} />
      </RenderIf>
      <RenderIf condition={isFeeDetailsLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      {feeDetails
        ?.filter((el) => el.percentage)
        ?.map((el) => {
          return (
            <RadioContainer key={el.from}>
              <Radio
                type="radio"
                id="useNew"
                name="card"
                value={el.from}
                checked={
                  convertToPrice(refillValue) === convertToPrice(el.from)
                }
                onChange={handleInputChange}
                disabled={isLoading}
              />
              <label htmlFor="useNew">
                {t('profile.percentageForAmount', {
                  percentage: el.percentage,
                  currency: Currency,
                  amount: convertToPrice(el.from),
                })}
              </label>
            </RadioContainer>
          )
        })}
      <Input
        inputMode="numeric"
        onChange={handleInputChange}
        value={refillValue}
        disabled={isLoading}
      />
      <ResultText>
        {t('cover.payGetLoaded', {
          v1: refillValue || 0,
          v2: addPercentage(+refillValue, tipsValue),
        })}
      </ResultText>

      <RenderIf condition={isAuthorized && !!userCardData}>
        <CheckBox
          isDisabled={isLoading}
          isChecked={useSavedCard}
          onClick={setUseSavedCard}
          label={
            <span>
              <span>{t('selfService.useSavedCard')}</span>
              {' ( '}
              <CCProvider cardMask={userCardData?.masked} />{' '}
              <span>
                {t('selfService.withEnding')}{' '}
                {userCardData?.masked?.split('x').pop()}
                {' )'}
              </span>
            </span>
          }
        />
        <br />
        <CheckBox
          isChecked={updateActiveCard}
          onClick={setUpdateActiveCard}
          label={t('selfService.newCardAsDefault')}
          isDisabled={useSavedCard || isLoading}
        />
        <br />
      </RenderIf>
      <Button
        buttonType="common"
        width="100%"
        onClick={refill}
        disabled={!refillValue || !DatatransActivated}
        isLoading={isLoading}
      >
        {t('cover.pay')}
      </Button>
    </RefillCard>
  )
}
