import { FC } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'

import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'
import { Button } from 'featureSliced/entities/Button'
import { useTranslations } from 'hooks/useTranslations'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'

type ButtonsCCProps = {
  handleLoyaltyButtonClick: () => void
}

export const ButtonsCC: FC<ButtonsCCProps> = ({ handleLoyaltyButtonClick }) => {
  const t = useTranslations()
  const [searchParams] = useSearchParams()
  const restaurants = useMySelector((state) => state.restaurants)
  const loginAvailable = useMySelector((state) => state.app.menu.loginAvailable)

  return (
    <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
      <RenderIf condition={!!restaurants?.length}>
        <NavLink to={getNavLink(searchParams, 'menu')}>
          <Button buttonType="common">{t('cover.menu')}</Button>
        </NavLink>
      </RenderIf>
      <RenderIf condition={loginAvailable}>
        <Button
          buttonType={restaurants?.length ? 'stroke' : 'common'}
          onClick={handleLoyaltyButtonClick}
        >
          {t('profile.card')}
        </Button>
      </RenderIf>
    </RenderIf>
  )
}
