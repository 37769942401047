import { useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'

import { RenderIf } from 'components/RenderIf'
import { Button } from 'featureSliced/entities/Button'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import { useMySelector } from 'hooks/useMySelector'
import { useTableStatus } from 'hooks/useTableStatus'
import { useTranslations } from 'hooks/useTranslations'

export const ButtonsQRM = () => {
  //
  // Hooks
  const t = useTranslations()
  const { checkTableAndMoveTo } = useTableStatus()
  const [searchParams] = useSearchParams()

  // Store
  const restaurants = useMySelector((state) => state.restaurants)
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const bookingVariant = useMySelector((state) => state.app.menu.bookingVariant)

  // State
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const [isMenuLoading, setIsMenuLoading] = useState(false)

  // Functions
  const handleMenuClick = () => {
    if (!isMenuLoading && !isPaymentLoading) {
      setIsMenuLoading(true)
      checkTableAndMoveTo('/menu', () => setIsMenuLoading(false))
    }
  }

  const handlePayClick = () => {
    if (!isMenuLoading && !isPaymentLoading) {
      setIsPaymentLoading(true)
      checkTableAndMoveTo('/payment', () => setIsPaymentLoading(false))
    }
  }

  return (
    <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
      {/* <RenderIf condition={!!restaurants?.length && bookingVariant !== 3}>
        <NavLink to={getNavLink(searchParams, 'menu')}>
          <Button buttonType="common" onClick={checkTableStatus}>
            {t('cover.menu')}
          </Button>
        </NavLink>
      </RenderIf> */}
      <RenderIf condition={!!restaurants?.length && bookingVariant !== 3}>
        <Button
          buttonType="common"
          onClick={handleMenuClick}
          isLoading={isMenuLoading}
        >
          {t('cover.menu')}
        </Button>
      </RenderIf>
      <RenderIf condition={bookingVariant === 3 || paymentVariant === 2}>
        <Button
          buttonType="stroke"
          onClick={handlePayClick}
          isLoading={isPaymentLoading}
        >
          {t('cover.pay')}
        </Button>
      </RenderIf>
    </RenderIf>
  )
}
