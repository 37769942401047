import styled from 'styled-components'

export const LogoContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoImage = styled.img`
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 75%;
  margin: auto;
  grid-row: 1 / -1;
`
