import { FC, useState } from 'react'
import { RenderIf } from 'components/RenderIf'
import { useMySelector } from 'hooks/useMySelector'

import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { ReactComponent as ClocheIcon } from 'assets/cloches/clocheSquare.svg'

import { IconFilled } from 'components/IconsContainers/styled'
import { ImageContainer, StyledImage } from './styled'

type CartProductType = {
  product?: IProduct
  numberToShow?: number
}

// TODO: rework
export const CartProductImage: FC<CartProductType> = ({
  product,
  numberToShow,
}) => {
  const color = useMySelector((state) => state.app.interfaceStyles).colour
  const [isImgErrored, setIsImageErrored] = useState(false)

  return (
    <RenderIf condition={Boolean(product)}>
      <ImageContainer count={numberToShow}>
        <StyledImage
          src={product?.imageUrl || 'ERRORED_IMAGE'}
          // onError={({ currentTarget }) => {
          //   setIsImageErrored(true)
          //   currentTarget.onerror = null // prevents looping
          // }}
          alt={product?.title}
          width="75"
          height="75"
        />
        {/* <RenderIf condition={!isImgErrored}>
          <StyledImage
            src={product?.imageUrl || 'ERRORED_IMAGE'}
            onError={({ currentTarget }) => {
              setIsImageErrored(true)
              currentTarget.onerror = null // prevents looping
            }}
            alt={product?.title}
            width="75"
            height="75"
          />
        </RenderIf>
        <RenderIf condition={isImgErrored}>
          <IconFilled color={`${color}80`}>
            <ClocheIcon />
          </IconFilled>
        </RenderIf> */}
      </ImageContainer>
    </RenderIf>
  )
}
