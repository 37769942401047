import { useSearchParams } from 'react-router-dom'
import { isAuth } from 'utils/isAuth'
import { qrMenuPOST } from 'api/qrMenu/POST'
import { useMySelector } from './useMySelector'
import { errorHandler } from 'components/ErrorHandler'

import { ITableCloudItems } from 'store/reducers/QRMenuTableReducer/types'
import { getParsedMenuItems } from 'featureSliced/shared/utils/getParsedMenuItems'
import { useOpenModal } from './useOpenModal'
import { ConfirmationModal } from 'featureSliced/features/ConfirmationModal'
import history from 'utils/history'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import { useEffect, useState } from 'react'
import { openLink } from 'featureSliced/shared/utils/openLink'
import { render } from 'react-dom'
import { DataTrans } from 'featureSliced/features/Datatrans/DataTrans'
import { IBucketProduct } from 'store/reducers/QRMenuBucketReducer/types'

export const usePayment = () => {
  const [searchParams] = useSearchParams()
  const products = useMySelector((state) => state.products.productList)
  const paymentVariant = useMySelector((state) => state.app.menu.paymentVariant)
  const tableId = searchParams.get('table')
  const openModal = useOpenModal()

  // State
  const [datatransPaymentId, setDatatransPaymentId] = useState<string | null>(
    null
  )

  // useEffect
  useEffect(() => {
    if (datatransPaymentId) {
      render(
        <DataTrans transactionId={datatransPaymentId} />,
        document.getElementById('root')
      )
    }
  }, [datatransPaymentId])

  const openRetryConfirmation = (
    productsToPay: ITableCloudItems[] | IBucketProduct[],
    tipsPercentage = 0,
    useSavedCard = false,
    useCustomerCard = false,
    updateActiveCard = false,
    index = 0
  ) => {
    openModal({
      id: `AUTH_MODAL_${index}`,
      title: '',
      components: [
        ({ closeThisModal }) => (
          <ConfirmationModal
            onConfirm={() => {
              startQRMAfterMealPayment(
                productsToPay,
                tipsPercentage,
                useSavedCard,
                useCustomerCard,
                updateActiveCard,
                index + 1
              )
              closeThisModal()
            }}
            onCancel={() => {
              closeThisModal()
              history.push(getNavLink(searchParams, ''))
            }}
            // TODO: translations
            okText="Retry"
            text="Failed to create order. Would you like to try again?"
          />
        ),
      ],
    })
  }

  const startQRMAfterMealPayment = async (
    productsToPay: ITableCloudItems[] | IBucketProduct[],
    tipsPercentage = 0,
    useSavedCard = false,
    useCustomerCard = false,
    updateActiveCard = false,
    index = 0
  ) => {
    try {
      const res = await qrMenuPOST.paymentRequest(
        paymentVariant === 1,
        tipsPercentage,
        tableId,
        useSavedCard,
        useCustomerCard,
        updateActiveCard,
        productsToPay.length
          ? productsToPay?.map((el) => ({
              ...el,
              menuItems: getParsedMenuItems(el.menuItems),
            }))
          : undefined
      )

      if (res?.PaymentProvider === 1 && res?.TransactionId) {
        if (res?.PaymentType === 'default') {
          setDatatransPaymentId(res?.TransactionId)
        }
        if (res?.PaymentType === 'customerCard') {
          // loyalty card
          // customer card case
          history.push(`/?datatransTrxId=${res?.TransactionId}`)
        }
        if (res?.PaymentType === 'savedCard') {
          // saved card case
          history.push(`/?datatransTrxId=${res?.TransactionId}`)
        }
      }

      if (res?.PaymentProvider === 2) {
        if (res?.RedirectUrl) {
          // default and savedCard must be redirected
          openLink(res?.RedirectUrl)
        } else if (res?.PaymentType === 'customerCard') {
          // loyalty card
          history.push(`/?orderGuid=${res?.Token}`)
          // history.push(`/?datatransTrxId=${res?.Token}`)
        }
      }
      // TODO: move to PaymentProvider === 1 or to PaymentProvider === 2
      // if (res?.orderNr) {
      //   history.replace(`/?payment_success_nr=${res?.orderNr}`) // possibly for c&c only
      // }

      return res
    } catch (e) {
      errorHandler(e)
      openRetryConfirmation(
        productsToPay,
        tipsPercentage,
        useSavedCard,
        useCustomerCard,
        updateActiveCard,
        index
      )
    }
  }

  return {
    startQRMAfterMealPayment,
  }
}
