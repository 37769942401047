import { IProduct } from 'store/reducers/CategoriesReducer/types'
import { ModalProps } from 'store/reducers/ModalsReducer/types'
import { useOpenModal } from 'hooks/useOpenModal'
import { AddCommonProduct } from './modals/AddCommonProduct'
import { AddMenuItemsProduct } from './modals/AddMenuItemsProduct'
import { AddMenuItems } from './modals/AddMenuItems'
import { AddSubTemplateProduct } from './modals/AddSubTemplateProduct'

export type OpenProductModalType = (
  product: IProduct,
  categoryName?: string
) => void

export const useOpenQRMProduct = (): OpenProductModalType => {
  const openModal = useOpenModal()

  const openProductModal = (product: IProduct, categoryName = '') => {
    const isCommonProduct =
      !product.subTemplateItems?.length && !product.menuItems?.length
    const isSubtemplate = product.subTemplateItems?.length
    const isMenuItems =
      product.menuItems?.length && !product.subTemplateItems?.length

    openModal({
      id: `ADD_PRODUCT_${product.id}`,
      title: product.subTemplateItems?.length ? product.title : categoryName,
      components: [
        ...(isCommonProduct
          ? [
              ({ closeThisModal }: ModalProps) => (
                <AddCommonProduct
                  product={product}
                  closeProduct={closeThisModal}
                />
              ),
            ]
          : []),
        ...(isSubtemplate
          ? [
              ({ closeThisModal }: ModalProps) => (
                <AddSubTemplateProduct
                  products={product.subTemplateItems}
                  categoryName={categoryName}
                  closeModal={closeThisModal}
                />
              ),
            ]
          : []),
        ...(isMenuItems
          ? [
              ({ onNext }: ModalProps) => (
                <AddMenuItemsProduct product={product} onNext={onNext} />
              ),
              ({ closeThisModal, onNext, onPrev, ...props }: ModalProps) => (
                <AddMenuItems
                  product={product}
                  closeModal={closeThisModal}
                  {...props}
                />
              ),
            ]
          : []),
      ],
    })
  }

  return openProductModal
}
