import { RenderIf } from 'components/RenderIf'
import { VideoBackgroundContainer } from './styled'
import { handleLocalStorage } from 'featureSliced/shared/hooks/handleLocalStorage'
import { FC } from 'react'

type VideoBackgroundType = {
  videoSrc?: string
}

export const VideoBackground: FC<VideoBackgroundType> = ({
  videoSrc = 'https://video-reis-rolle.s3.eu-central-1.amazonaws.com/backgroundVideoTEMP.mp4',
}) => {
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])

  return (
    <>
      <RenderIf
        condition={Boolean(
          guid?.toUpperCase() === '9712049C-1325-430F-B0C8-F6D64E5CF9C5' &&
            registerId == 67
        )}
      >
        <VideoBackgroundContainer
          src={videoSrc}
          typeof="video/mp4"
          controls={false}
          playsInline
          autoPlay
          muted
          loop
        />
      </RenderIf>
    </>
  )
}
