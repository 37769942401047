import { FC } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { ClickCollectNotificationsModal } from './clickCollectNotifications'
import { QRMenuCallWaiterModal } from './qrMenuCallWaiter'

type NotificationsModalProps = {
  onDeclineClose: () => void
  onSuccessClose: () => void
}

export const NotificationsModal: FC<NotificationsModalProps> = ({
  onDeclineClose,
  onSuccessClose,
}) => {
  return (
    <>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '0'}>
        <QRMenuCallWaiterModal
          onDeclineClose={onDeclineClose}
          onSuccessClose={onSuccessClose}
        />
      </RenderIf>
      <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
        <ClickCollectNotificationsModal />
      </RenderIf>
    </>
  )
}
