import { FC } from 'react'
import { useTranslations } from 'hooks/useTranslations'
import { Button } from 'featureSliced/entities/Button'
import { AskWaiterModalContainer } from './styled'

type AgeProtectionModalProps = {
  isHighlyProtected?: boolean
  startPayment: () => void
}

export const AgeProtectionModal: FC<AgeProtectionModalProps> = ({
  isHighlyProtected,
  startPayment,
}) => {
  const t = useTranslations()
  return (
    <AskWaiterModalContainer>
      <p>
        {isHighlyProtected
          ? t('common.ageConfirmationDisclaimer18')
          : t('common.ageConfirmationDisclaimer')}
      </p>
      <Button buttonType="common" width="100%" onClick={startPayment}>
        {t('buttons.ok')}
      </Button>
    </AskWaiterModalContainer>
  )
}
