import { FC, useState, useEffect } from 'react'
import { convertToPrice } from '../../../utils/convertToPrice'
import { useTranslations } from '../../../hooks/useTranslations'
import { useMySelector } from '../../../hooks/useMySelector'
import { getPrice } from '../../../utils/getProductPrice'
import history from '../../../utils/history'
import { Fab, Action } from 'react-tiny-fab'
import { ProductBasket } from './styled'

import menu from '../../../assets/meatballs.svg'
import basket from '../../../assets/basket.svg'
import filters from '../../../assets/settings.svg'
import search from '../../../assets/search.svg'
import barcode from '../../../assets/barcode.svg'
import { RenderIf } from '../../../components/RenderIf'
import { useSearchParams } from 'react-router-dom'
import { roundValueTo } from '../../../utils/roundValueTo'
import { TableStatuses } from 'store/reducers/QRMenuTableReducer/types'
import { getNavLink } from 'featureSliced/shared/utils/getNavLink'
import { getTotalPrice as getTotalQRMPrice } from 'featureSliced/shared/utils/getTotalPrice'

type FooterType = {
  setIsInputShowing: () => void
  openAllergensModal: () => void
  openBarcodeModal: () => void
}

export const Footer: FC<FooterType> = ({
  setIsInputShowing,
  openAllergensModal,
  openBarcodeModal,
}) => {
  // Hooks
  const t = useTranslations()
  const [searchParams] = useSearchParams()

  // Store
  const { interfaceStyles } = useMySelector((state) => state.app)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const selectedProducts = useMySelector((state) => state.selectedProducts)
  const products = useMySelector((state) => state.products.productList)

  const selectedQRMProducts = useMySelector((state) => state.bucket.products)
  const tableStatus = useMySelector((state) => state.qrMenuTable.tableStatus)
  const { paymentVariant, bookingVariant } = useMySelector(
    (state) => state.app.menu
  )

  // State
  const [total, setTotal] = useState<string | null>(null)
  const [totalQRM, setTotalQRM] = useState<string | null>(null)

  // useEffects
  useEffect(() => {
    const totalToSet = getTotalPrice()
    if (totalToSet) {
      const newPrice = convertToPrice(totalToSet)
      setTotal(`${newPrice} ${Currency || ''}`)
    }
    if (!totalToSet) {
      setTotal(null)
    }
  }, [selectedProducts, Currency])

  useEffect(() => {
    const totalToSet = getTotalQRMPrice(selectedQRMProducts)
    if (totalToSet) {
      const newPrice = convertToPrice(totalToSet)
      setTotalQRM(`${newPrice} ${Currency || ''}`)
    }
    if (!totalToSet) {
      setTotalQRM(null)
    }
  }, [selectedQRMProducts, products, Currency])

  const getTotalPrice = () => {
    const totalToSet = selectedProducts.reduce((acc, curr) => {
      let fullProductData = products?.find((p) => p.id === curr.id)
      if (!fullProductData) {
        fullProductData = products
          ?.reduce((acc: any[], current) => {
            if (current.subTemplateItems) {
              return [...acc, ...current.subTemplateItems]
            }
            return acc
          }, [])
          ?.find((st) => st.id === curr.id)
      }
      const { menuItems = [] } = curr
      const currentPrice = getPrice(fullProductData)
      return (
        acc +
        (currentPrice +
          menuItems?.reduce(
            (acc, { count, price }) => acc + count * price,
            0
          )) *
          (curr.amount ?? 0)
      )
    }, 0)
    return totalToSet
  }

  const getTotalAmount = () => {
    return selectedProducts?.reduce((acc, { amount }) => {
      return roundValueTo(acc + amount)
    }, 0)
  }

  const handleQRMBasketClick = () => {
    if (totalQRM && tableStatus !== TableStatuses.BUSY) {
      if (paymentVariant === 1) {
        history.push(getNavLink(searchParams, 'payment'))
      }
      if (paymentVariant === 2) {
        history.push(getNavLink(searchParams, 'order'))
      }
    }
  }

  const handleBasketClick = () => {
    if (total && process.env.REACT_APP_IS_CLIC === '1') {
      history.push(getNavLink(searchParams, 'payment'))
    }
  }

  return (
    <>
      <Fab
        event={'click'}
        icon={<img src={menu} alt={t('cover.menu')} height="40" width="40" />}
        mainButtonStyles={{ backgroundColor: interfaceStyles.colour }}
        style={{
          bottom:
            navigator.onLine &&
            (bookingVariant === 2 || process.env.REACT_APP_IS_CLIC === '1')
              ? 80
              : 0,
          right: 0,
          zIndex: 2,
        }}
        alwaysShowTitle
      >
        <Action text={t('footer.filters')} onClick={openAllergensModal}>
          <img src={filters} alt="filters" height="40" width="40" />
        </Action>
        <Action text={t('footer.search')} onClick={setIsInputShowing}>
          <img src={search} alt="search" height="40" width="40" />
        </Action>
        {/* NOTE scanner is temporary hidden */}
        {/* <Action text={t('footer.scan')} onClick={openBarcodeModal}>
          <img src={barcode} alt="scanner" height="40" width="40" />
        </Action> */}
      </Fab>
      <RenderIf
        condition={
          navigator.onLine &&
          bookingVariant === 2 &&
          process.env.REACT_APP_IS_CLIC === '0'
        }
      >
        <Fab
          event={'click'}
          icon={
            <ProductBasket count={getTotalAmount()}>
              <img src={basket} alt="check-out" height="24" width="31" />
            </ProductBasket>
          }
          mainButtonStyles={{ backgroundColor: interfaceStyles.colour }}
          style={{ bottom: 0, right: 0, zIndex: 2 }}
          text={totalQRM as string}
          alwaysShowTitle={!!totalQRM}
          onClick={handleQRMBasketClick}
        />
      </RenderIf>
      <RenderIf
        condition={navigator.onLine && process.env.REACT_APP_IS_CLIC === '1'}
      >
        <Fab
          event={'click'}
          icon={
            <ProductBasket count={getTotalAmount()}>
              <img src={basket} alt="check-out" height="24" width="31" />
            </ProductBasket>
          }
          mainButtonStyles={{ backgroundColor: interfaceStyles.colour }}
          style={{ bottom: 0, right: 0, zIndex: 2 }}
          text={total as string}
          alwaysShowTitle={!!total}
          onClick={handleBasketClick}
        />
      </RenderIf>
    </>
  )
}
